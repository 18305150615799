import { Flex } from '@radix-ui/themes'
import { MapControl, ControlPosition } from '@vis.gl/react-google-maps'
import styles from '../map.module.css'

type CustomZoomControlProps = {
  zoom: number
  onZoomChange: (zoom: number) => void
}

export default function CustomZoomControl({
  zoom,
  onZoomChange,
}: CustomZoomControlProps) {
  const handleZoomIn = () => {
    if (zoom < 18) {
      onZoomChange(zoom + 1)
    }
  }

  const handleZoomOut = () => {
    if (zoom > 1) {
      onZoomChange(zoom - 1)
    }
  }
  return (
    <MapControl position={ControlPosition.RIGHT_CENTER}>
      <div className={styles.zoomControl}>
        <Flex direction="column">
          <button onClick={handleZoomIn} aria-label="Zoom In">
            +
          </button>
          <button onClick={handleZoomOut} aria-label="Zoom Out">
            -
          </button>
        </Flex>
      </div>
    </MapControl>
  )
}
