'use client'

import clsx from 'clsx'
import styles from './checklist.module.css'

import Card from '@/components/Card'
import DOMPurify from 'isomorphic-dompurify'
import ZbLink from '@/components/ZbLink'
import { wrapTables } from '@/hooks/useSanitizedHtml'

interface ChecklistItem {
  lead: string
  image: {
    src: string
    alt: string
  }
  cta: {
    url: string
    title: string
  }
  title: string
}

interface ChecklistProps {
  title?: string
  text?: string
  items: ChecklistItem[]
  btnLink?: string
  btnText?: string
  className?: string
}

export function Checklist({
  title,
  text,
  items,
  btnLink,
  btnText,
  className,
}: ChecklistProps) {
  const handleCardClick = (url: string, event: React.MouseEvent) => {
    if ((event.target as HTMLElement).closest('a, button')) {
      return
    }
    window.open(url, '_blank')
  }

  const handleKeyDown = (url: string, event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      window.open(url, '_blank')
    }
  }

  return (
    <div className={clsx(styles.checklist, className)}>
      {title && <h2 className={clsx(styles.checklist__title)}>{title}</h2>}
      {text && (
        <div
          className={clsx(styles.checklist__subtitle)}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(wrapTables(text) || ''),
          }}
        />
      )}

      <div className={clsx(styles.checklist__content)}>
        {items.map((item, index) => (
          <div
            key={index}
            className={styles.cardWrapper}
            onClick={(e) => handleCardClick(item.cta.url, e)}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => handleKeyDown(item.cta.url, e)}
          >
            <Card
              image={{
                url: item.image.src,
                subtitle: item.image.alt,
                alt: item.image.alt,
                size: { w: 380, h: 300 },
              }}
              text={item.lead}
              link={{
                text: item.cta.title,
                url: item.cta.url,
                linkInNewTab: true,
                download: true,
              }}
              withButton={false}
              withShadow={false}
              ctaBtnSize="small"
            />
          </div>
        ))}
      </div>

      {btnLink && btnText && (
        <div className={clsx(styles.checklist__cta)}>
          <ZbLink
            to={btnLink}
            type="button"
            size="medium"
            className={styles['checklist__cta-button']}
          >
            <span>{btnText}</span>
          </ZbLink>
        </div>
      )}
    </div>
  )
}
