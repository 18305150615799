import YouTubeVideo from '@/components/YoutubeVideo'
import styles from './contentWidthYoutube.module.css'
import clsx from 'clsx'

type ZbContentWidthYoutubeProps = {
  youtubeUrl: string
  className?: string
}

export default function ZbContentWidthYoutube({
  youtubeUrl,
  className,
}: ZbContentWidthYoutubeProps) {
  return (
    <section className={clsx(styles.contentWidthYoutube, className)}>
      <YouTubeVideo youtubeUrl={youtubeUrl} />
    </section>
  )
}
