import clsx from 'clsx'
import styles from './contentImage.module.css'
import ZbImage from '@/components/ZbImage'
import ZbBadge from '@/components/Badge'
import { Grid } from '@/components/Grid'

type ZbContentImageProps = {
  src: string
  alt?: string
  badgeTitle?: string
  badgeSubtitle?: string
  overlayTitle?: string
  alignRight?: boolean
  uuid?: string
  aspectRatio?: number
  className?: string
}

const nonCoverImagesList = [
  'c56bfbce-6dc9-444f-b67e-7ea301c2909b',
  '110fe2d0-8519-4c00-8990-bf38d78504ef',
]

export default function ZbContentImage({
  src,
  alt = '',
  badgeTitle = '',
  badgeSubtitle = '',
  overlayTitle = '',
  alignRight = true,
  uuid = '',
  className,
}: ZbContentImageProps) {
  const contain = (uuid: string) => {
    return nonCoverImagesList.includes(uuid)
  }

  return (
    <Grid className={clsx(styles.contentImage__container, className)}>
      <section className={styles.image}>
        <div className={styles.wrapper}>
          <ZbImage
            src={src}
            alt={alt}
            className={clsx(styles.img, { [styles.contain]: contain(uuid) })}
            fill
          />

          {badgeTitle && badgeSubtitle && (
            <div
              className={clsx(
                styles.badge,
                alignRight ? styles.badge__right : styles.badge__left
              )}
            >
              <ZbBadge
                title={badgeTitle}
                subtitle={badgeSubtitle}
                rotateRight={alignRight}
              />
            </div>
          )}

          {overlayTitle && <div className={styles.overlay}>{overlayTitle}</div>}
        </div>
      </section>
    </Grid>
  )
}
