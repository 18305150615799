'use client'

import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import ReCAPTCHA from 'react-google-recaptcha'
import { TextField, MenuItem } from '@mui/material'
import styles from './ContactForm.module.css'
import { useAppSettings } from '@/context/AppSettingsContext'
import { useLocale } from 'next-intl'

type FormData = {
  salutation: 'mr' | 'mrs' | ''
  firstName: string
  lastName: string
  address?: string
  postalCode?: string
  city?: string
  email: string
  phone?: string
  location: string
  category: string
  message?: string
}

interface ContactFormProps {
  confirmationPage?: string
  locations?: Array<{ value: string; text: string }>
  categories?: Array<{ value: string; text: string }>
  hideLocation?: boolean
}

export default function ContactForm({
  confirmationPage,
  locations = [],
  categories = [],
  hideLocation = false,
}: ContactFormProps) {
  const locale = useLocale()
  const { appSettings } = useAppSettings()
  const appTranslations = appSettings?.translations
  const [isRecaptchaValid, setIsRecaptchaValid] = useState(false)

  const formSchema = z.object({
    salutation: z.enum(['mr', 'mrs']),
    firstName: z
      .string()
      .min(1, appTranslations?.[`${locale}_contactform_firstname_error`]),
    lastName: z
      .string()
      .min(1, appTranslations?.[`${locale}_contactform_lastname_error`]),
    address: z.string().optional(),
    postalCode: z.string().optional(),
    city: z.string().optional(),
    email: z
      .string()
      .email(appTranslations?.[`${locale}_contactform_email_error`]),
    phone: z.string().optional(),
    location: hideLocation
      ? z.string().optional()
      : z
          .string()
          .min(1, appTranslations?.[`${locale}_contactform_location_error`]),
    category: z
      .string()
      .min(1, appTranslations?.[`${locale}_contactform_category_error`]),
    message: z.string().optional(),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
  })

  const onRecaptchaChange = (token: string | null) => {
    setIsRecaptchaValid(!!token)
  }

  const handleFormSubmit = async (data: FormData) => {
    try {
      const confirmationPath = confirmationPage
        ? new URL(confirmationPage).pathname
        : ''

      const response = await fetch('/backend/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          confirmationPage: confirmationPath,
        }),
      })

      if (!response.ok) {
        throw new Error('Submission failed')
      }

      const result = await response.json()

      if (result.confirmationPage) {
        window.location.href = result.confirmationPage.startsWith('/')
          ? result.confirmationPage
          : `/${result.confirmationPage}`
      }

      reset()
    } catch (error) {
      console.error('Form submission error:', error)
    }
  }

  const menuProps = {
    PaperProps: {
      sx: {
        '& .MuiList-root': {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  }

  const renderTextField = (
    name: keyof FormData,
    label: string,
    options?: {
      required?: boolean
      multiline?: boolean
      rows?: number
    }
  ) => (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { ref, ...field } }) => (
        <TextField
          {...field}
          inputRef={ref}
          label={`${label}${options?.required ? '*' : ''}`}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          fullWidth
          variant="outlined"
          multiline={options?.multiline}
          rows={options?.rows}
        />
      )}
    />
  )

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.formCol}>
          <h4 className={styles.title}>
            {appTranslations?.[`${locale}_contactform_titlemain`]}
          </h4>
        </div>
      </div>

      <form onSubmit={handleSubmit(handleFormSubmit)} className={styles.form}>
        <div className={styles.row}>
          <div className={styles.formCol}>
            <h5 className={styles.sectionTitle}>
              {appTranslations?.[`${locale}_contactform_section1`]}
            </h5>
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formColHalf}>
            <Controller
              name="salutation"
              control={control}
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={`${appTranslations?.[`${locale}_contactform_salutaion_label`]}*`}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                  variant="outlined"
                  select
                  SelectProps={{ MenuProps: menuProps }}
                >
                  <MenuItem value="mr">
                    {
                      appTranslations?.[
                        `${locale}_contactform_salutation_option_mr`
                      ]
                    }
                  </MenuItem>
                  <MenuItem value="mrs">
                    {
                      appTranslations?.[
                        `${locale}_contactform_salutation_option_mrs`
                      ]
                    }
                  </MenuItem>
                </TextField>
              )}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formCol}>
            {renderTextField(
              'firstName',
              appTranslations?.[`${locale}_contactform_firstname_label`] ||
                'First Name',
              { required: true }
            )}
          </div>
          <div className={styles.formCol}>
            {renderTextField(
              'lastName',
              appTranslations?.[`${locale}_contactform_lastname_label`] ||
                'Last Name',
              { required: true }
            )}
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formCol}>
            {renderTextField(
              'address',
              appTranslations?.[`${locale}_contactform_street_label`] ||
                'Address'
            )}
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formColQuarter}>
            {renderTextField(
              'postalCode',
              appTranslations?.[`${locale}_contactform_postalcode_label`] ||
                'Postal Code'
            )}
          </div>
          <div className={styles.formCol}>
            {renderTextField(
              'city',
              appTranslations?.[`${locale}_contactform_city_label`] || 'City'
            )}
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formCol}>
            {renderTextField(
              'email',
              appTranslations?.[`${locale}_contactform_email_label`] || 'Email',
              { required: true }
            )}
          </div>
          <div className={styles.formCol}>
            {renderTextField(
              'phone',
              appTranslations?.[`${locale}_contactform_phone_label`] || 'Phone'
            )}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.formCol}>
            <h5 className={styles.sectionTitle}>
              {appTranslations?.[`${locale}_contactform_section2`]}
            </h5>
          </div>
        </div>

        {!hideLocation && (
          <div className={styles.formRow}>
            <div className={styles.formColHalf}>
              <Controller
                name="location"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label={`${appTranslations?.[`${locale}_contactform_location_label`]}*`}
                    error={!!errors.location}
                    helperText={errors.location?.message}
                    fullWidth
                    variant="outlined"
                    SelectProps={{ MenuProps: menuProps }}
                  >
                    {locations[locale]?.map((location) => (
                      <MenuItem key={location.value} value={location.value}>
                        {location.text}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </div>
          </div>
        )}

        <div className={styles.formRow}>
          <div className={styles.formColHalf}>
            <Controller
              name="category"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={`${appTranslations?.[`${locale}_contactform_category_label`]}*`}
                  error={!!errors.category}
                  helperText={errors.category?.message}
                  fullWidth
                  variant="outlined"
                  SelectProps={{ MenuProps: menuProps }}
                >
                  {categories[locale]?.map((category) => (
                    <MenuItem key={category.value} value={category.value}>
                      {category.text}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formCol}>
            {renderTextField(
              'message',
              appTranslations?.[`${locale}_contactform_remarks_label`] ||
                'Remarks',
              { multiline: true, rows: 4 }
            )}
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.recaptchaCol}>
            <ReCAPTCHA
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
              onChange={onRecaptchaChange}
              hl={locale}
            />
          </div>
          <div className={styles.submitCol}>
            <button
              type="submit"
              disabled={!isValid || !isRecaptchaValid}
              className={styles.submitButton}
            >
              {appTranslations?.[`${locale}_contactform_send_label`]}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
