'use client'

import { Button } from '@radix-ui/themes'
import styles from './checkButton.module.css'
import { forwardRef } from 'react'
import clsx from 'clsx'
import ZbSelectedButton from '@/components/buttons/SelectedButton'

type ZbCheckButtonProps = {
  isSelected?: boolean
  text?: string
  disabled?: boolean
  className?: string
  localizedLabel?: string
  withHover?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const ZbCheckButton = forwardRef<HTMLButtonElement, ZbCheckButtonProps>(
  (
    {
      isSelected = false,
      text = '',
      disabled = false,
      className,
      localizedLabel,
      withHover = true,
      onClick,
    },
    ref
  ) => {
    return (
      <>
        {isSelected ? (
          <ZbSelectedButton />
        ) : (
          <Button
            ref={ref}
            className={clsx(
              styles.btn,
              withHover ? styles.btnWithHover : '',
              disabled ? styles.disabled : styles.active,
              className
            )}
            type="button"
            aria-label={localizedLabel}
            disabled={disabled}
          >
            <span className={styles.text}>{text}</span>
          </Button>
        )}
      </>
    )
  }
)

ZbCheckButton.displayName = 'ZbCheckButton'

export default ZbCheckButton
