import {
  DrupalMedia,
  DrupalNode,
  DrupalParagraph,
  DrupalTaxonomyTerm,
} from 'next-drupal'

interface MediaImage extends DrupalMedia {
  field_media_image: {
    resourceIdObjMeta: {
      alt: string
      height: number
      width: number
    }
    image_style_uri: {
      gallery_desktop: string
      gallery_mobile: string
    }
    uri: {
      url: string
    }
  }
}

export interface NodePage extends DrupalNode {
  type: 'node--page'
  field_meta_title: string
  field_meta_description: string
  field_content_only: boolean
  field_search_exclude: boolean
  field_basic_page_content?: (
    | ParagraphDirectionsContact
    | ParagraphRatings
    | ParagraphImageLogoGrid
    | ParagraphImageVideoContentWidth
    | ParagraphTeaserBlog
    | ParagraphStage
    | ParagraphChecklist
    | ParagraphContentTeaserGrid
    | ParagraphTeaserDownload
    | ParagraphTeaserFaq
    | ParagraphFaqs
    | ParagraphContinuoustext
    | ParagraphFromLibrary
    | ParagraphGallery
    | ParagraphGridPackagingMaterial
    | ParagraphH1Introelement
    | ParagraphHowto
    | ParagraphInfoboxProcessZebraBox
    | ParagraphTeaserContact
    | ParagraphHomeMap
    | ParagraphPageTitleElement
    | ParagraphTeaserLocation
    | ParagraphLocationteaserTab
    | ParagraphTabElement
    | ParagraphTeaserNextBestActions
    | ParagraphTextCentered
    | ParagraphText2Columns
    | ParagraphTextImageCentred
    | ParagraphDivider
    | ParagraphUsps34Column
    | ParagraphUspsCarRental
    | ParagraphVolumecalculator
  )[]
}

export interface NodeArticle extends DrupalNode {
  type: 'node--article'
  field_article_content: (
    | ParagraphChecklist
    | ParagraphContinuoustext
    | ParagraphContentTeaserGrid
    | ParagraphDirectionsContact
    | ParagraphDivider
    | ParagraphFaqs
    | ParagraphFromLibrary
    | ParagraphGallery
    | ParagraphGridPackagingMaterial
    | ParagraphHowto
    | ParagraphImageLogoGrid
    | ParagraphImageVideoContentWidth
    | ParagraphInfoboxProcessZebraBox
    | ParagraphLocationteaserTab
    | ParagraphMap
    | ParagraphPageTitleElement
    | ParagraphRatings
    | ParagraphStage
    | ParagraphSubnavBlog
    | ParagraphSubnavServices
    | ParagraphTabElement
    | ParagraphTabElementContent
    | ParagraphTeaserBig
    | ParagraphTeaserBlog
    | ParagraphTeaserContact
    | ParagraphTeaserDownload
    | ParagraphTeaserFaq
    | ParagraphTeaserLocation
    | ParagraphTeaserLocationEntry
    | ParagraphTeaserNextBestActions
    | ParagraphTeaserNextBestActionsEntry
    | ParagraphTeaserTextImage
    | ParagraphText2Columns
    | ParagraphTextCentered
    | ParagraphTextImageCentred
    | ParagraphTextImageSimple
    | ParagraphUsps34Column
    | ParagraphUsps34ColumnEntry
    | ParagraphUspsCarRental
    | ParagraphUspsCarRentalPrice
    | ParagraphVirtualTour
    | ParagraphVolumecalculator
    | ParagraphVolumecalculatorQuickstart
  )[]
  field_featured: boolean
  field_category: TermTags[]
  field_meta_description: string
  field_meta_title: string
  field_preview_image: MediaImage
  field_preview_lead: {
    processed: string
    value: string
    format: string
  }
}

export interface ParagraphDirectionsContact extends DrupalParagraph {
  type: 'paragraph--directions_contact'
  field_confirmationpage: {
    url: string
    title?: string
  }
  field_submit_confirm_text: string
  field_submit_confirm_title: string
  field_links: {
    url: string
    title?: string
  }
  field_sitecode: string
  field_text_left: {
    processed: string
    value: string
    format: string
  }
  field_text_center: {
    processed: string
    value: string
    format: string
  }
}

export interface ParagraphRatings extends DrupalParagraph {
  type: 'paragraph--ratings'
  field_content: ParagraphRatingsEntry[]
  field_media: MediaImage
  field_cta: {
    url: string
    title?: string
  }
  field_link_in_new_tab: boolean
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_title_left: string
  field_title_right: string
}

export interface ParagraphRatingsEntry extends DrupalParagraph {
  type: 'paragraph--ratings_entry'
  field_media: MediaImage
  field_title: string
  field_lead: {
    processed: string
    value: string
    format: string
  }
}

export interface ParagraphImageLogoGrid extends DrupalParagraph {
  type: 'paragraph--image_logo_grid'
  field_medias: MediaImage
}

export interface ParagraphImageVideoContentWidth extends DrupalParagraph {
  type: 'paragraph--image_video_content_width'
  field_media: MediaImage
  field_image_caption: string
  field_eyecatcher_text: string
  field_eyecatcher_title: string
  field_video: string
}

export interface ParagraphTeaserBlogGrid extends DrupalParagraph {
  type: 'paragraph--teaser_blog_grid'
}

export interface ParagraphTeaserBlog extends DrupalParagraph {
  type: 'paragraph--teaser_blog'
  field_cta: {
    url: string
    title?: string
  }
  field_title: string
}
export interface ParagraphStage extends DrupalParagraph {
  type: 'paragraph--stage'
  field_media: MediaImage
  field_title: string
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_eyecatcher_text: string
  field_eyecatcher_title: string
  field_ticker: ParagraphStageTickerEntry[]
}
export interface ParagraphStageTickerEntry extends DrupalParagraph {
  type: 'paragraph--stage_tickerentry'
  field_stars: number[]
  field_text: string
}
export interface ParagraphChecklist extends DrupalParagraph {
  type: 'paragraph--checklist'
  field_cta: {
    url: string
    title?: string
  }
  field_title: string
  field_entries: ParagraphChecklistEntry[]
  field_lead: {
    processed: string
    value: string
    format: string
  }
}
export interface ParagraphChecklistEntry extends DrupalParagraph {
  type: 'paragraph--checklist_entry'
  field_media: MediaImage
  field_cta: {
    url: string
    title?: string
  }
  field_title: string
}

export interface ParagraphContentTeaserGrid extends DrupalParagraph {
  type: 'paragraph--content_teaser_grid'
  field_entries:
  | ParagraphTeaserContentTeaserGridEntry[]
  | ParagraphFromLibrary[]
  field_title: string
  field_lead: {
    processed: string
    value: string
    format: string
  }
}
export interface ParagraphTeaserContentTeaserGridEntry extends DrupalParagraph {
  type: 'paragraph--teaser_content_teaser_grid_entry'
  field_media: MediaImage
  field_title: string
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_cta: {
    url: string
    title?: string
  }
  field_cta_buttonmode: boolean
}
export interface ParagraphTeaserDownload extends DrupalParagraph {
  type: 'paragraph--teaser_download'
  field_media: MediaImage
  field_cta: {
    url: string
    title?: string
  }
}

export interface ParagraphTeaserFaq extends DrupalParagraph {
  type: 'paragraph--teaser_faq'
  field_cta: {
    url: string
    title?: string
  }
  field_entries: ParagraphFaqEntry[] | ParagraphFromLibrary[]
  field_title: string
  field_backgroundcolor: boolean
  field_lead: {
    processed: string
    value: string
    format: string
  }
}

export interface ParagraphFaqs extends DrupalParagraph {
  type: 'paragraph--faqs'
  field_content: ParagraphFaqCluster[]
}

export interface ParagraphFaqCluster extends DrupalParagraph {
  type: 'paragraph--faq_cluster'
  field_content: ParagraphFaqEntry[] | ParagraphFromLibrary[]
  field_title: string
}

export interface ParagraphFaqEntry extends DrupalParagraph {
  type: 'paragraph--faq_entry'
  field_paragraphs_text: {
    processed: string
    value: string
    format: string
  }
  field_link: string
  field_text: string
}

export interface ParagraphContinuoustext extends DrupalParagraph {
  type: 'paragraph--continuoustext'
  field_text_left: {
    processed: string
    value: string
    format: string
  }
  field_text_right: {
    processed: string
    value: string
    format: string
  }
  field_cta: {
    url: string
    title?: string
  }
  field_cta_2: {
    url: string
    title?: string
  }
}

export interface ParagraphForm extends DrupalParagraph {
  type: 'paragraph--form'
  field_confirmationpage: {
    url: string
    title?: string
  }
}
export interface ParagraphFromLibrary extends DrupalParagraph {
  type: 'paragraph--from_library'
  field_reusable_paragraph:
  | ParagraphDirectionsContact
  | ParagraphRatings
  | ParagraphImageLogoGrid
  | ParagraphImageVideoContentWidth
  | ParagraphTeaserBlog
  | ParagraphStage
  | ParagraphChecklist
  | ParagraphContentTeaserGrid
  | ParagraphTeaserDownload
  | ParagraphTeaserFaq
  | ParagraphFaqs
  | ParagraphContinuoustext
  | ParagraphForm
  | ParagraphFaqCluster
  | ParagraphFaqEntry
  | ParagraphGallery
  | ParagraphGridPackagingMaterial
  | ParagraphGridPackagingMaterialEntry
  | ParagraphTeaserBig
  | ParagraphH1Introelement
  | ParagraphLocationteaserTab
  | ParagraphLocationteaserTabEntry
  | ParagraphPagetitleElementMap
  | ParagraphPageTitleElementRating
  | ParagraphSubnavBlogPreview
  | ParagraphSubnavLocations
  | ParagraphSubnavBlog
  | ParagraphSubnavServices
  | ParagraphTabElement
  | ParagraphTeaserLocation
  | ParagraphTeaserLocationEntry
  | ParagraphTeaserNextBestActions
  | ParagraphTeaserNextBestActionsEntry
  | ParagraphTextImageCentred
  | ParagraphTextImageSimple
  | ParagraphTeaserTextImage
  | ParagraphTextCentered
  | ParagraphText2Columns
  | ParagraphUsps34Column
  | ParagraphUsps34ColumnEntry
  | ParagraphUspsCarRental
}

export interface ParagraphGallery extends DrupalParagraph {
  type: 'paragraph--gallery'
  field_medias: MediaImage[]
}
export interface ParagraphGridPackagingMaterial extends DrupalParagraph {
  type: 'paragraph--grid_packaging_material'
  field_content: ParagraphGridPackagingMaterialEntry[]
}
export interface ParagraphGridPackagingMaterialEntry extends DrupalParagraph {
  type: 'paragraph--grid_packaging_material_entry'
  field_title: string
  field_media: string
  field_text: string
  field_categories: TermPackagingMaterialCategories[]
  field_size: string
  field_price: number
  field_eyecatcher_text: string
}

export interface ParagraphTeaserBig extends DrupalParagraph {
  type: 'paragraph--teaser_big'
  field_media: MediaImage
  field_image_right: string
  field_cta: {
    url: string
    title?: string
  }
  field_teaser: string
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_title: string
}

export interface ParagraphH1Introelement extends DrupalParagraph {
  type: 'paragraph--h1_introelement'
  field_cta: {
    url: string
    title?: string
  }
  field_title: string
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_list_entries: string[]
  field_list_title: string
}

export interface ParagraphHowto extends DrupalParagraph {
  type: 'paragraph--howto'
  field_entries: ParagraphHowtoEntry[]
  field_title: string
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_video: string
}
export interface ParagraphHowtoEntry extends DrupalParagraph {
  type: 'paragraph--howto_entry'
  field_title: string
  field_lead: {
    processed: string
    value: string
    format: string
  }
}

export interface ParagraphInfoboxProcessZebraBox extends DrupalParagraph {
  type: 'paragraph--infobox_process_zebra_box'
  field_media_left: MediaImage
  field_media_center: MediaImage
  field_media_right: MediaImage
  field_title: string
  field_cta: {
    url: string
    title?: string
  }
  field_backgroundcolor: boolean
  field_text_left: {
    processed: string
    value: string
    format: string
  }
  field_text_center: {
    processed: string
    value: string
    format: string
  }
  field_text_right: {
    processed: string
    value: string
    format: string
  }
  field_title_left: string
  field_title_center: string
  field_title_right: string
}

export interface ParagraphMap extends DrupalParagraph {
  type: 'paragraph--map'
  field_entries: ParagraphFromLibrary[] | ParagraphMapEntry[]
}

export interface ParagraphMapEntry extends DrupalParagraph {
  type: 'paragraph--map_entry'
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_cta: {
    url: string
    title?: string
  }
  field_highlighted: boolean
  field_latitude: string
  field_longitude: string
  field_sitecode: string
  field_title: string
}

export interface ParagraphHomeMap extends DrupalParagraph {
  type: 'paragraph--home_map'
  field_entries: ParagraphHomeMapSliderentry[]
  field_title: string
  field_lead: {
    processed: string
    value: string
    format: string
  }
}

export interface ParagraphHomeMapSliderentry extends DrupalParagraph {
  type: 'paragraph--home_map_sliderentry'
  field_media: MediaImage
  field_title: string
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_latitude: string
  field_cta: {
    url: string
    title?: string
  }
  field_longitude: string
  field_popup_content: {
    processed: string
    value: string
    format: string
  }
  field_popup_title: string
  field_sitecode: string
}

export interface ParagraphTeaserContact extends DrupalParagraph {
  type: 'paragraph--teaser_contact'
  field_cta: {
    url: string
    title?: string
  }
  field_cta_2: {
    url: string
    title?: string
  }
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_title: string
}

export interface ParagraphPageTitleElement extends DrupalParagraph {
  type: 'paragraph--page_title_element'
  field_title: string
  field_content:
  | ParagraphPageTitleElementEyecatcher[]
  | ParagraphPageTitleElementLinks[]
  | ParagraphPagetitleElementLocationdetail[]
  | ParagraphPagetitleElementMap[]
  | ParagraphPageTitleElementRating[]
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_title_as_h2: boolean
}

export interface ParagraphPageTitleElementEyecatcher extends DrupalParagraph {
  type: 'paragraph--page_title_element_eyecatcher'
  field_text: string
  field_title: string
}

export interface ParagraphPageTitleElementLinks extends DrupalParagraph {
  type: 'paragraph--pagetitle_element_links'
  field_links: {
    url: string
    title?: string
  }[]
  field_title: string
}

export interface ParagraphPagetitleElementLocationdetail
  extends DrupalParagraph {
  type: 'paragraph--pagetitle_element_locationdetail'
  field_cta: {
    url: string
    title?: string
  }
  field_title: string
  field_lead: {
    processed: string
    value: string
    format: string
  }
}

export interface ParagraphPagetitleElementMap extends DrupalParagraph {
  type: 'paragraph--page_title_element_map'
  field_media: MediaImage
}

export interface ParagraphPageTitleElementRating extends DrupalParagraph {
  type: 'paragraph--pagetitle_element_rating'
  field_media: MediaImage
  field_cta: {
    url: string
    title?: string
  }
  field_title: string
}

export interface ParagraphTeaserLocation extends DrupalParagraph {
  type: 'paragraph--teaser_location'
  field_entries: ParagraphTeaserLocationEntry[]
  field_title: string
  field_lead: {
    processed: string
    value: string
    format: string
  }
}

export interface ParagraphLocationteaserTab extends DrupalParagraph {
  type: 'paragraph--locationteaser_tab'
  field_entries: ParagraphLocationteaserTabEntry[] | ParagraphFromLibrary[]
}

export interface ParagraphLocationteaserTabEntry extends DrupalParagraph {
  type: 'paragraph--locationteaser_tab_entry'
  field_media: MediaImage
  field_cta: {
    url: string
    title?: string
  }
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_latitude: string
  field_longitude: string
  field_sitecode: string
  field_is_teaser: boolean
  field_teaser_overlay_text: string
  field_title: string
}

export interface ParagraphSubnavBlogPreview extends DrupalParagraph {
  type: 'paragraph--subnav_blog_preview'
  field_title_right: string
}
export interface ParagraphSubnavLocations extends DrupalParagraph {
  type: 'paragraph--subnav_locations'
  field_links: {
    url: string
    title?: string
  }[]
  field_locations: {
    url: string
    title?: string
  }[]
  field_title_left: string
  field_title_right: string
}

export interface ParagraphSubnavBlog extends DrupalParagraph {
  type: 'paragraph--subnav_blog'
  field_categories: TermTags[]
  field_links: {
    url: string
    title?: string
  }[]
  field_cta: {
    url: string
    title?: string
  }
  field_title_categories: string
  field_title_left: string
  field_title_right: string
}

export interface ParagraphSubnavServices extends DrupalParagraph {
  type: 'paragraph--subnav_services'
  field_title_right: string
}

export interface ParagraphTabElement extends DrupalParagraph {
  type: 'paragraph--tab_element'
  field_paragraphs: ParagraphTabElementContent[]
}

export interface ParagraphTabElementContent extends DrupalParagraph {
  type: 'paragraph--tab_element_content'
  field_link: {
    url: string
    title?: string
  }
  field_paragraphs:
  | ParagraphFromLibrary[]
  | ParagraphDirectionsContact[]
  | ParagraphRatings[]
  | ParagraphImageLogoGrid[]
  | ParagraphImageVideoContentWidth[]
  | ParagraphTeaserBlog[]
  | ParagraphStage[]
  | ParagraphChecklist[]
  | ParagraphContentTeaserGrid[]
  | ParagraphTeaserDownload[]
  | ParagraphFaqs[]
  | ParagraphTeaserFaq[]
  | ParagraphContinuoustext[]
  | ParagraphGallery[]
  | ParagraphGridPackagingMaterial[]
  | ParagraphTeaserBig[]
  | ParagraphHowto[]
  | ParagraphInfoboxProcessZebraBox[]
  | ParagraphMap[]
  | ParagraphTeaserContact[]
  | ParagraphPageTitleElement[]
  | ParagraphTeaserLocation[]
  | ParagraphLocationteaserTab[]
  | ParagraphTeaserNextBestActions[]
  | ParagraphTextCentered[]
  | ParagraphText2Columns[]
  | ParagraphTextImageCentred[]
  | ParagraphTextImageSimple[]
  | ParagraphTeaserTextImage[]
  | ParagraphDivider[]
  | ParagraphUsps34Column[]
  | ParagraphUspsCarRental[]
  | ParagraphVirtualTour[]
  field_title: string
}

export interface ParagraphDivider extends DrupalParagraph {
  type: 'paragraph--divider'
}

export interface ParagraphTeaserLocationEntry extends DrupalParagraph {
  type: 'paragraph--teaser_location_entry'
  field_media: MediaImage
  field_cta: {
    url: string
    title?: string
  }
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_title: string
}

export interface ParagraphTeaserNextBestActions extends DrupalParagraph {
  type: 'paragraph--teaser_next_best_actions'
  field_content: ParagraphTeaserNextBestActionsEntry[]
  field_title: string
}

export interface ParagraphTeaserNextBestActionsEntry extends DrupalParagraph {
  type: 'paragraph--teaser_next_best_actions_entry'
  field_media: MediaImage
  field_cta: {
    url: string
    title?: string
  }
  field_teaser: string
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_title: string
  field_link_in_new_tab: boolean
}

export interface ParagraphTextImageCentred extends DrupalParagraph {
  type: 'paragraph--text_image_centred'
  field_medias4: MediaImage[]
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_title: string
}

export interface ParagraphTextImageSimple extends DrupalParagraph {
  type: 'paragraph--text_image_simple'
  field_media: MediaImage
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_image_right: boolean
  field_teaser: string
  field_title: string
  field_paragraphs_text: {
    processed: string
    value: string
    format: string
  }
}

export interface ParagraphTeaserTextImage extends DrupalParagraph {
  type: 'paragraph--teaser_text_image'
  field_media: MediaImage
  field_image_right: boolean
  field_text_2: {
    processed: string
    value: string
    format: string
  }
  field_cta: {
    url: string
    title?: string
  }
  field_cta_2: {
    url: string
    title?: string
  }
  field_title: string
  field_lead: {
    processed: string
    value: string
    format: string
  }
  field_eyecatcher_text: string
  field_eyecatcher_title: string
}

export interface ParagraphTextCentered extends DrupalParagraph {
  type: 'paragraph--text_centered'
  field_backgroundcolor: boolean
  field_lead: {
    processed: string
    value: string
    format: string
  }
}
export interface ParagraphText2Columns extends DrupalParagraph {
  type: 'paragraph--text_2_columns'
  field_text_left: {
    processed: string
    value: string
    format: string
  }
  field_text_right: {
    processed: string
    value: string
    format: string
  }
  field_title: string
}

export interface ParagraphUsps34Column extends DrupalParagraph {
  type: 'paragraph--usps_3_4_column'
  field_4_columns: boolean
  field_entries: ParagraphUsps34ColumnEntry[] | ParagraphFromLibrary[]
  field_title: string
  field_backgroundcolor: boolean
}

export interface ParagraphUsps34ColumnEntry extends DrupalParagraph {
  type: 'paragraph--usps_3_4_column_entry'
  field_media: MediaImage
  field_title: string
  field_lead: {
    processed: string
    value: string
    format: string
  }
}

export interface ParagraphUspsCarRental extends DrupalParagraph {
  type: 'paragraph--usps_car_rental'
  field_title: string
  field_entries: ParagraphUspsCarRentalPrice[]
  field_texts_left: {
    processed: string
    value: string
    format: string
  }[]
  field_title_left: string
  field_title_right: string
}

export interface ParagraphUspsCarRentalPrice extends DrupalParagraph {
  type: 'paragraph--usps_car_rental_price'
  field_highlighted: boolean
  field_price: number
  field_title: string
}

export interface ParagraphVirtualTour extends DrupalParagraph {
  type: 'paragraph--virtual_tour'
}

export interface ParagraphVolumecalculator extends DrupalParagraph {
  type: 'paragraph--volumecalculator'
}

export interface ParagraphVolumecalculatorQuickstart extends DrupalParagraph {
  type: 'paragraph--volumecalculator_quickstart'
  field_storagetype_archive: string
  field_footer: {
    processed: string
    value: string
    format: string
  }
  field_title: string
  field_backgroundcolor: boolean
  field_hint: string
  field_entries: ParagraphFromLibrary[] | ParagraphLocationteaserTabEntry[]
  field_text_step_1: string
  field_text_step_2: string
  field_text_step_3: string
  field_business_title: string
  field_private_title: string
  field_storagetype_goods: string
}

export interface TermPackagingMaterialCategories extends DrupalTaxonomyTerm {
  type: 'taxonomy_term--packaging_material_categories'
}
export interface TermTags extends DrupalTaxonomyTerm {
  type: 'taxonomy_term--tags'
}

export enum ResourceType {
  NODE__PAGE = 'node--page',
  NODE__ARTICLE = 'node--article',
  PARAGRAPH__DIRECTIONS_CONTACT = 'paragraph--directions_contact',
  PARAGRAPH__RATINGS = 'paragraph--ratings',
  PARAGRAPH__GALLERY = 'paragraph--gallery',
  PARAGRAPH__RATINGS_ENTRY = 'paragraph--ratings_entry',
  PARAGRAPH__IMAGE_LOGO_GRID = 'paragraph--image_logo_grid',
  PARAGRAPH__IMAGE_VIDEO_CONTENT_WIDTH = 'paragraph--image_video_content_width',
  PARAGRAPH__TEASER_BLOG_GRID = 'paragraph--teaser_blog_grid',
  PARAGRAPH__TEASER_BLOG = 'paragraph--teaser_blog',
  PARAGRAPH__STAGE = 'paragraph--stage',
  PARAGRAPH__STAGE_TICKERENTRY = 'paragraph--stage_tickerentry',
  PARAGRAPH__CHECKLIST = 'paragraph--checklist',
  PARAGRAPH__CHECKLIST_ENTRY = 'paragraph--checklist_entry',
  PARAGRAPH__CONTENT_TEASER_GRID = 'paragraph--content_teaser_grid',
  PARAGRAPH__TEASER_CONTENT_TEASER_GRID_ENTRY = 'paragraph--teaser_content_teaser_grid_entry',
  PARAGRAPH__TEASER_DOWNLOAD = 'paragraph--teaser_download',
  PARAGRAPH__TEASER_FAQ = 'paragraph--teaser_faq',
  PARAGRAPH__FAQS = 'paragraph--faqs',
  PARAGRAPH__FAQ_CLUSTER = 'paragraph--faq_cluster',
  PARAGRAPH__FAQ_ENTRY = 'paragraph--faq_entry',
  PARAGRAPH__CONTINUOUS_TEXT = 'paragraph--continuoustext',
  PARAGRAPH__FORM = 'paragraph--form',
  PARAGRAPH__FROM_LIBRARY = 'paragraph--from_library',
  PARAGRAPH__GRID_PACKAGING_MATERIAL = 'paragraph--grid_packaging_material',
  PARAGRAPH__GRID_PACKAGING_MATERIAL_ENTRY = 'paragraph--grid_packaging_material_entry',
  PARAGRAPH__TEASER_BIG = 'paragraph--teaser_big',
  PARAGRAPH__H1_INTROELEMENT = 'paragraph--h1_introelement',
  PARAGRAPH__HOWTO = 'paragraph--howto',
  PARAGRAPH__HOWTO_ENTRY = 'paragraph--howto_entry',
  PARAGRAPH__INFOBOX_PROCESS_ZEBRA_BOX = 'paragraph--infobox_process_zebra_box',
  PARAGRAPH__MAP = 'paragraph--map',
  PARAGRAPH__MAP_ENTRY = 'paragraph--map_entry',
  PARAGRAPH__HOME_MAP = 'paragraph--home_map',
  PARAGRAPH__HOME_MAP_SLIDERENTRY = 'paragraph--home_map_sliderentry',
  PARAGRAPH__TEASER_CONTACT = 'paragraph--teaser_contact',
  PARAGRAPH__PAGETITLE_ELEMENT = 'paragraph--pagetitle_element',
  PARAGRAPH__PAGETITLE_ELEMENT_RATING = 'paragraph--pagetitle_element_rating',
  PARAGRAPH__PAGETITLE_ELEMENT_MAP = 'paragraph--pagetitle_element_map',
  PARAGRAPH__PAGETITLE_ELEMENT_LINKS = 'paragraph--pagetitle_element_links',
  PARAGRAPH__PAGETITLE_ELEMENT_LOCATIONDETAIL = 'paragraph--pagetitle_element_locationdetail',
  PARAGRAPH__PAGETITLE_ELEMENT_EYECATCHER = 'paragraph--pagetitle_element_eyecatcher',
  PARAGRAPH__TEASER_LOCATION = 'paragraph--teaser_location',
  PARAGRAPH__LOCATIONTEASER_TAB = 'paragraph--locationteaser_tab',
  PARAGRAPH__LOCATIONTEASER_TAB_ENTRY = 'paragraph--locationteaser_tab_entry',
  PARAGRAPH__SUBNAV_BLOG_PREVIEW = 'paragraph--subnav_blog_preview',
  PARAGRAPH__SUBNAV_LOCATIONS = 'paragraph--subnav_locations',
  PARAGRAPH__SUBNAV_BLOG = 'paragraph--subnav_blog',
  PARAGRAPH__SUBNAV_SERVICES = 'paragraph--subnav_services',
  PARAGRAPH__TAB_ELEMENT = 'paragraph--tab_element',
  PARAGRAPH__TAB_ELEMENT_CONTENT = 'paragraph--tab_element_content',
  PARAGRAPH__DIVIDER = 'paragraph--divider',
  PARAGRAPH__TEASER_LOCATION_ENTRY = 'paragraph--teaser_location_entry',
  PARAGRAPH__TEASER_NEXT_BEST_ACTIONS = 'paragraph--teaser_next_best_actions',
  PARAGRAPH__TEASER_NEXT_BEST_ACTIONS_ENTRY = 'paragraph--teaser_next_best_actions_entry',
  PARAGRAPH__TEXT_IMAGE_CENTRED = 'paragraph--text_image_centred',
  PARAGRAPH__TEXT_IMAGE_SIMPLE = 'paragraph--text_image_simple',
  PARAGRAPH__TEASER_TEXT_IMAGE = 'paragraph--teaser_text_image',
  PARAGRAPH__TEXT_CENTERED = 'paragraph--text_centered',
  PARAGRAPH__TEXT_2_COLUMNS = 'paragraph--text_2_columns',
  PARAGRAPH__USPS_3_4_COLUMN = 'paragraph--usps_3_4_column',
  PARAGRAPH__USPS_3_4_COLUMN_ENTRY = 'paragraph--usps_3_4_column_entry',
  PARAGRAPH__USPS_CAR_RENTAL = 'paragraph--usps_car_rental',
  PARAGRAPH__USPS_CAR_RENTAL_PRICE = 'paragraph--usps_car_rental_price',
  PARAGRAPH__VIRTUAL_TOUR = 'paragraph--virtual_tour',
  PARAGRAPH__VOLUME_CALCULATOR = 'paragraph--volumecalculator',
  PARAGRAPH__VOLUME_CALCULATOR_QUICKSTART = 'paragraph--volumecalculator_quickstart',
  TERM__TAGS = 'taxonomy_term--tags',
  TERM__PACKAGING_MATERIAL_CATEGORIES = 'taxonomy_term--packaging_material_categories',
}

export const ResourceTypeIncludes = {
  NODE__PAGE: [
    'field_basic_page_content',
    'field_basic_page_content.field_content',
    'field_basic_page_content.field_media_left.field_media_image',
    'field_basic_page_content.field_media_center.field_media_image',
    'field_basic_page_content.field_media.field_media_image',
    'field_basic_page_content.field_entries',
    'field_basic_page_content.field_entries.field_reusable_paragraph.paragraphs',
    'field_basic_page_content.field_content.field_media.field_media_image',
    'field_basic_page_content.field_entries.field_media.field_media_image',
    'field_basic_page_content.field_entries.field_reusable_paragraph.paragraphs.field_media.field_media_image',
    'field_basic_page_content.field_paragraphs.field_paragraphs',
    'field_basic_page_content.field_medias.field_media_image',
    'field_basic_page_content.field_ticker',
    'field_basic_page_content.field_medias4.field_media_image',
    'field_basic_page_content.field_paragraphs.field_paragraphs',
    'field_basic_page_content.field_paragraphs.field_paragraphs.field_entries',
    'field_basic_page_content.field_content.field_content',
    'field_basic_page_content.field_content.field_content.field_reusable_paragraph.paragraphs',
    'field_basic_page_content.field_content.field_categories',
    'field_basic_page_content.field_paragraphs.field_paragraphs.field_medias.field_media_image',
    'field_basic_page_content.field_paragraphs.field_paragraphs.field_media.field_media_image',
    'field_basic_page_content.field_paragraphs.field_paragraphs.field_entries.field_media.field_media_image',
    'field_basic_page_content.field_paragraphs.field_paragraphs.field_entries.field_reusable_paragraph.paragraphs.field_media.field_media_image',
    'field_basic_page_content.field_paragraphs.field_paragraphs.field_content.field_media.field_media_image'
  ].join(','),

  NODE__ARTICLE: [
    'field_article_content',
    'field_article_content.field_content',
    'field_preview_image',
    'field_preview_image.field_media_image',
    'field_category',
    'field_article_content.field_media.field_media_image',
    'field_article_content.field_entries',
    'field_article_content.field_entries.field_media.field_media_image',
    'field_article_content.field_content.field_content'
  ].join(',')
} as const
