export default function SVGMinus({
  size = { width: '18px', height: '10px' },
  color = 'black',
  className,
}: {
  size?: { width: string; height: string }
  color?: string
  className?: string
}) {
  return (
    <svg
      className={className}
      width={size.width}
      height={size.height}
      viewBox="0 0 25 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill={color}
    >
      <g stroke="none" strokeWidth="3">
        <g id="UI-minus" transform="translate(-12.000000, -12.000000)">
          <g
            id="Line-5"
            transform="translate(24.131728, 24.131728) rotate(45.000000) translate(-24.131728, -24.131728) translate(15.646447, 15.646447)"
          >
            <polygon
              transform="translate(8.485281, 8.485281) scale(-1, 1) rotate(-135.000000) translate(-8.485281, -8.485281) "
              points="19.9852814 7.98528137 19.9852814 8.98528137 -3.01471863 8.98528137 -3.01471863 7.98528137"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
