import Link from 'next/link'
import { useLocale } from 'next-intl'
import { format } from 'date-fns'
import DOMPurify from 'isomorphic-dompurify'
import ZbImage from '@/components/ZbImage'
import styles from './BlogArticlePreview.module.css'
import { wrapTables } from '@/hooks/useSanitizedHtml'

type ArticleProps = {
  article: {
    id: string
    title: string
    date: string
    text?: string
    image?: string
    link?: string
    category?: string
  }
}

export default function BlogArticlePreview({ article }: ArticleProps) {
  const locale = useLocale()

  const getArticleLink = () => {
    return article.link || `/${locale}/node/${article.id}`
  }

  const getArticleDate = () => {
    try {
      return format(new Date(article.date), 'dd.MM.yyyy, HH:mm')
    } catch (error) {
      console.error('Error formatting date:', error)
      return ''
    }
  }

  return (
    <div className={styles.blogArticlePreview}>
      <Link href={getArticleLink()} className={styles.imageLink}>
        {article.image && (
          <div className={styles.imageWrapper}>
            <ZbImage
              alt={article.title}
              className={styles.image}
              fill
              loading="lazy"
              src={article.image}
            />
          </div>
        )}
      </Link>

      <div className={styles.category}>
        <span className={styles.date}>{getArticleDate()}</span>
        <span className={styles.separator}>/</span>
        {article?.category && (
          <span className={styles.categoryName}>{article.category}</span>
        )}
      </div>

      <Link href={getArticleLink()} className={styles.titleLink}>
        <h3 className={styles.title}>{article.title}</h3>
      </Link>

      {article.text && (
        <Link href={getArticleLink()} className={styles.textLink}>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(wrapTables(article.text) || ''),
            }}
          />
        </Link>
      )}
    </div>
  )
}
