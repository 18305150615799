'use client'

import { useState } from 'react'
import styles from './virtualTour.module.css'
import clsx from 'clsx'
import Spinner from '../Spinner'

type VirtualTourProps = {
  screenReadersTitle?: string
  url?: string
  width?: string
  className?: string
}

export default function ZbVirtualTour({
  screenReadersTitle = 'Zebrabox Virtual Tour',
  url = 'https://mpembed.com/show/?m=Ep33aSL9r8B&mpu=419',
  width = '100%',
  className,
}: VirtualTourProps) {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <div className={clsx(styles.container, className)}>
      {isLoading && (
        <div className={styles.loader}>
          <Spinner />
        </div>
      )}
      <iframe
        className={clsx(styles.iframe, { [styles.hidden]: isLoading })}
        src={url}
        title={screenReadersTitle}
        width={width}
        onLoad={() => setIsLoading(false)}
      />
    </div>
  )
}
