import { drupalBaseUrl } from '@/lib/config'
import { DrupalClient } from 'next-drupal'

export const drupal = new DrupalClient(drupalBaseUrl || '', {
  previewSecret: process.env.DRUPAL_PREVIEW_SECRET,
  debug: process.env.NODE_ENV === 'development',
})

export const absoluteUrl = (input: string): string => {
  return `${drupalBaseUrl}${input}`
}
