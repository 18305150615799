import { Container } from '@radix-ui/themes'
import { ReactNode } from 'react'
import styles from './grid.module.css'
import clsx from 'clsx'

interface GridProps {
  children: ReactNode
  className?: string
}

export function Grid({ children, className }: GridProps) {
  return (
    <Container className={clsx(styles.container, className)}>
      <div className={clsx(styles.grid)}>{children}</div>
    </Container>
  )
}
