'use client'

import QuickstartCard from '@/components/VolumeCalculatorQuickstart/QuickstartCard'
import { useQuickstartContext } from '@/components/VolumeCalculatorQuickstart/quickstartContext'
import ZbSlider from '@/components/ZbSlider'
import stepOneStyles from '../StepOne/stepone.module.css'

export const quickstartTypes = {
  BusinessCustomer: 0,
  PrivateCustomer: 1,
  ArchiveContent: 2,
  GoodsContent: 3,
}

export default function StepThree({ sizes }: { sizes: any }) {
  const { quickstartSelections } = useQuickstartContext()

  const locationSizes = sizes.filter(
    (location) => location.siteCode === quickstartSelections.location
  )

  const getImageSource = (size) => {
    if (
      quickstartSelections.customerType === quickstartTypes.BusinessCustomer
    ) {
      if (quickstartSelections.contentType === quickstartTypes.ArchiveContent) {
        return size.image_ba || ''
      } else if (
        quickstartSelections.contentType === quickstartTypes.GoodsContent
      ) {
        return size.image_bw || ''
      }
    } else {
      return size.image_p || ''
    }
  }

  const noSelection = quickstartSelections.size === null

  return (
    <ZbSlider
      centerModeDesktop={false}
      centerModeMobile={true}
      centerPaddingMobile="40px"
      className={stepOneStyles.slider}
      dotsMobile={false}
    >
      {locationSizes[0].sizes.map((size, index) => (
        <QuickstartCard
          key={index}
          sizeName={size.name}
          title={{ text: size.name, size: 'h4' }}
          imgZoom={false}
          cardType="carousel"
          step={3}
          gap="0"
          noSelection={noSelection}
          className="sizesCard"
          image={{
            url: getImageSource(size),
            alt: size.name,
          }}
        />
      ))}
    </ZbSlider>
  )
}
