import SVGCheckmark from '@/components/SVGIcons/SVGCheckmark'
import styles from './selectedButton.module.css'
import { Button } from '@radix-ui/themes'

type ZbSelectedButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  localizedLabel?: string
}

export default function ZbSelectedButton({
  localizedLabel,
  onClick,
}: ZbSelectedButtonProps) {
  return (
    <Button
      className={styles.selected__button}
      type="button"
      aria-label={localizedLabel}
      onClick={onClick}
    >
      <span>
        <SVGCheckmark size={{ width: '24px', height: '24px' }} color="black" />
      </span>
    </Button>
  )
}
