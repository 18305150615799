'use client'

import { createContext, useContext, useState, ReactNode } from 'react'

interface QuickstartContextType {
  currentStep: number
  setCurrentStep: (step: number) => void
  quickstartSelections: {
    location: string | null
    customerType: number | null
    contentType: number | null
    size: string | null
  }
  setLocation: (location: string | null) => void
  setCustomerType: (customerType: number | null) => void
  setContentType: (contentType: number | null) => void
  setSize: (size: string | null) => void
}

const QuickstartContext = createContext<QuickstartContextType | undefined>(
  undefined
)

export const useQuickstartContext = () => {
  const context = useContext(QuickstartContext)
  if (!context) {
    throw new Error(
      'useQuickstartContext must be used within a QuickstartProvider'
    )
  }
  return context
}

export const QuickstartProvider = ({ children }: { children: ReactNode }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [quickstartSelections, setQuickstartSelections] = useState({
    location: null,
    customerType: null,
    contentType: null,
    size: null,
  })

  const setLocation = (location: string | null) => {
    setQuickstartSelections((prevSelections) => ({
      ...prevSelections,
      location,
    }))
  }

  const setCustomerType = (customerType: number | null) => {
    setQuickstartSelections((prevSelections) => ({
      ...prevSelections,
      customerType,
    }))
  }

  const setContentType = (contentType: number | null) => {
    setQuickstartSelections((prevSelections) => ({
      ...prevSelections,
      contentType,
    }))
  }

  const setSize = (size: string | null) => {
    setQuickstartSelections((prevSelections) => ({
      ...prevSelections,
      size,
    }))
  }

  return (
    <QuickstartContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        quickstartSelections,
        setLocation,
        setCustomerType,
        setContentType,
        setSize,
      }}
    >
      {children}
    </QuickstartContext.Provider>
  )
}
