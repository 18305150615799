import { FAQEntry } from '@/components/FAQ/faq.types'

export function JSONSchemaFAQ({ props }: { props: FAQEntry }) {
  const jsonData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: props.map((entry) => ({
      '@type': 'Question',
      name: entry.field_text,
      acceptedAnswer: {
        '@type': 'Answer',
        text: entry.field_paragraphs_text.processed || '',
      },
    })),
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(jsonData),
      }}
    />
  )
}
