'use client'

import { useState, useEffect } from 'react'

export function useIsMobile(width: number = 768) {
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' ? window.matchMedia(`(max-width: ${width}px)`).matches : true
  )

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${width}px)`)
    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches)
    }

    setIsMobile(mediaQuery.matches)

    mediaQuery.addEventListener('change', handleMediaQueryChange)

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange)
    }
  }, [width])

  return isMobile
}
