'use client'

import styles from './carrental.module.css'
import DOMPurify from 'isomorphic-dompurify'
import clsx from 'clsx'
import { wrapTables } from '@/hooks/useSanitizedHtml'
import { Box } from '@radix-ui/themes'
import 'react-perfect-scrollbar/dist/css/styles.css'
import CustomScrollbar from '@/components/HtmlWithOptionalScrollbar/CustomScrollbar'

interface PriceItem {
  field_title: string
  field_price: number
  field_highlighted: boolean
}

interface ListItem {
  processed: string
}

interface CarRentalProps {
  itemsList: ListItem[]
  pricesList: PriceItem[]
  mainTitle: string
  leftColumnTitle: string
  rightColumnTitle: string
}

//TO DO: Add currency to i18n.config per country
const CURRENCY_CODE = 'CHF'

export default function CarRental({
  itemsList,
  pricesList,
  mainTitle,
  leftColumnTitle,
  rightColumnTitle,
}: CarRentalProps) {
  const renderListItem = (item: ListItem, index: number) => (
    <li key={index} className={styles.listItem}>
      <span className={styles.numberCircle}>{index + 1}</span>
      <div
        className={styles.itemText}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(wrapTables(item.processed) || ''),
        }}
      />
    </li>
  )

  const renderPriceRow = (item: PriceItem, index: number) => (
    <tr key={index}>
      <td
        className={clsx(styles.tableData, {
          [styles.bold]: item.field_highlighted,
        })}
      >
        {item.field_title}
      </td>
      <td className={clsx(styles.tableData, styles.rightAlign)}>
        <span
          className={clsx({
            [styles.highlightedPrice]: item.field_highlighted,
          })}
        >
          {CURRENCY_CODE} {Math.trunc(item.field_price)}.-
        </span>
      </td>
    </tr>
  )

  return (
    <div className={styles.zbCarRental}>
      <div className={styles.container}>
        <div className={styles.headerRow}>
          <div className={styles.headerCenter}>
            <h2>{mainTitle}</h2>
          </div>
        </div>

        <div className={styles.contentRow}>
          <div className={styles.firstColumn}>
            <div className={styles.columnHeader}>
              <h3>{leftColumnTitle}</h3>
            </div>
            <ul>{itemsList.map(renderListItem)}</ul>
          </div>

          <div className={styles.secondColumn}>
            <div className={styles.columnHeader}>
              <h3>{rightColumnTitle}</h3>
            </div>
            <Box>
              <CustomScrollbar className="scrollbar-custom">
                <table>
                  <tbody>{pricesList.map(renderPriceRow)}</tbody>
                </table>
              </CustomScrollbar>
            </Box>
          </div>
        </div>
      </div>
    </div>
  )
}
