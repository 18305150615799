'use client'

import Slider from 'react-slick'
import styles from './zbslider.module.css'
import CircleArrowButton from '@/components/buttons/CircleArrowButton'
import clsx from 'clsx'
import { ReactNode, useEffect, useRef } from 'react'

function PrevArrow(props: any) {
  const { onClick } = props
  return (
    <CircleArrowButton
      className={styles.sliderButtonPrev}
      direction="left"
      onClick={onClick}
    />
  )
}

function NextArrow(props: any) {
  const { onClick } = props
  return (
    <CircleArrowButton
      className={styles.sliderButtonNext}
      direction="right"
      onClick={onClick}
    />
  )
}

export default function ZbSlider({
  centerModeDesktop = false,
  centerModeMobile = false,
  centerPaddingDesktop = '25px',
  centerPaddingMobile = '0px',
  children,
  className = '',
  dotsDesktop = false,
  dotsMobile = true,
}: {
  centerModeDesktop?: boolean
  centerModeMobile?: boolean
  dotsMobile?: boolean
  dotsDesktop?: boolean
  className?: string
  children: ReactNode
  centerPaddingDesktop?: string
  centerPaddingMobile?: string
}) {
  let childrenLength = Array.isArray(children) ? children.length : 1
  const sliderContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const resizeSliderItems = () => {
      const container = sliderContainerRef.current

      if (!container) return
      const sliderItems = Array.from(container.querySelectorAll('.slick-slide'))

      let maxHeight = Math.max(...sliderItems.map((item) => item.clientHeight))

      sliderItems.forEach((item) => {
        ;(item as HTMLElement).style.height = `${maxHeight}px`
      })
    }

    const observer = new ResizeObserver(resizeSliderItems)
    observer.observe(sliderContainerRef.current!)

    resizeSliderItems()

    return () => observer.disconnect()
  }, [sliderContainerRef])

  const settings = {
    adaptiveHeight: true,
    centerModeMobile: centerModeDesktop,
    centerPadding: centerPaddingDesktop,
    className,
    dots: dotsDesktop,
    infinite: childrenLength > 3,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: childrenLength > 3 ? 3 : childrenLength,
    swipeToSlide: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          centerMode: centerModeMobile,
          centerPadding: centerPaddingMobile,
          dots: dotsMobile,
          draggable: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          centerMode: centerModeMobile,
          centerPadding: centerPaddingMobile,
          dots: dotsMobile,
          draggable: true,
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <div style={{ width: '100%' }} ref={sliderContainerRef}>
      <Slider className={clsx(styles.sliderContainer, className)} {...settings}>
        {children}
      </Slider>
    </div>
  )
}
