'use client'

import styles from './card.module.css'
import ZbLink, { ZbLinkProps } from '@/components/ZbLink'
import ZbImage from '@/components/ZbImage'
import { Flex, Heading } from '@radix-ui/themes'
import DOMPurify from 'isomorphic-dompurify'
import clsx from 'clsx'
import { wrapTables } from '@/hooks/useSanitizedHtml'

type CardVariant = 'default' | 'tabs' | 'carousel' | 'infobox' | 'contentGrid'

type CardProps = {
  variant?: CardVariant
  image?: {
    url: string
    size?: { w: number; h: number }
    subtitle?: string
    alt: string
  }
  link?: {
    text: string
    url: string
    type?: string
    linkInNewTab?: boolean
    download?: boolean
  }
  cta?: {
    text: string
    url: string
    type: 'unstyled' | 'button' | 'base' | 'arrow'
    linkInNewTab?: boolean
    download?: boolean
  }
  redirect?: boolean
  imgZoom?: boolean
  title?: { text: string; size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' }
  text: string
  withButton?: boolean
  withShadow?: boolean
  hoverPointer?: boolean
  ctaBtnSize?: ZbLinkProps['size']
  onPageTitleElement?: boolean
  alignStart?: boolean
  cardType?: 'default' | 'tabs' | 'carousel'
  onTeaserTextImage?: boolean
  children?: React.ReactNode
  className?: string
  clickableCard?: boolean
  isCtaExternal?: boolean
}

export default function Card({
  variant = 'default',
  image = { url: '', size: { w: 320, h: 250 }, subtitle: '', alt: '' },
  link = {
    text: '',
    url: '',
    type: 'unstyled',
    linkInNewTab: false,
    download: false,
  },
  cta = {
    text: '',
    url: '',
    type: 'unstyled',
    linkInNewTab: false,
    download: false,
  },
  imgZoom = false,
  title = { text: '', size: 'h4' },
  text = '',
  withButton = true,
  withShadow = true,
  ctaBtnSize = 'medium',
  onPageTitleElement = false,
  alignStart = false,
  cardType = 'default',
  onTeaserTextImage = false,
  children,
  hoverPointer,
  className = '',
  clickableCard = false,
  isCtaExternal = false,
}: CardProps) {
  const sanitizedText = DOMPurify.sanitize(wrapTables(text) || '')

  const titleBox = (
    <Heading as={title.size} className={styles.cardTitle}>
      {title.text}
    </Heading>
  )

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!clickableCard) return

    if ((e.target as HTMLElement).tagName === 'A') return

    const links = e.currentTarget.querySelectorAll('a')
    const validLink = Array.from(links).find((link) => link.textContent?.trim())

    if (validLink instanceof HTMLAnchorElement) {
      validLink.click()
    }
  }

  return (
    <Flex
      className={clsx(
        styles.cardContainer,
        variant === 'infobox' && styles.infoboxCard,
        variant === 'contentGrid' && styles.contentGridCard,
        styles[className],
        clickableCard && styles.clickableCard,
        {
          [styles.cardShadow]: withShadow,
          [styles.hoverPointer]: hoverPointer,
        }
      )}
      direction="column"
      align={alignStart ? 'start' : 'stretch'}
      onClick={handleClick}
    >
      {image.url !== '' ? (
        <div
          className={clsx(
            styles.cardImage,
            cardType !== 'default' ? styles[cardType + 'CardImage'] : '',
            {
              [styles.zoomableImg]: imgZoom,
            }
          )}
        >
          <ZbImage
            className={styles[cardType + 'Image']}
            alt={image.alt}
            height={image.size?.h}
            src={image.url}
            width={image.size?.w}
            zoomImage={imgZoom}
          />
        </div>
      ) : null}
      <Flex
        direction="column"
        gap="10px"
        pt="10px"
        pb="10px"
        className={clsx(styles[cardType + 'CardInfo'], {
          [styles.alignStart]: alignStart,
        })}
      >
        <div
          className={clsx(
            styles.cardTitleContainer,
            cardType !== 'default' ? styles[cardType + 'CardTitle'] : ''
          )}
        >
          {titleBox}
        </div>
        <div
          className={clsx(
            styles.cardText,
            cardType !== 'default' ? styles[cardType + 'CardText'] : ''
          )}
          dangerouslySetInnerHTML={{ __html: sanitizedText }}
        />
        {children}
        {onTeaserTextImage ? (
          <div className={styles.inlineButtonsContainer}>
            {withButton && (
              <div className={styles.textImageButton}>
                <ZbLink
                  to={cta?.url}
                  type="button"
                  size={ctaBtnSize}
                  linkInNewTab={cta?.linkInNewTab}
                  download={cta?.download}
                >
                  <span>{cta?.text}</span>
                </ZbLink>
              </div>
            )}
            {link?.url ? (
              <div
                className={clsx(
                  styles.cardLink,
                  styles.textImageLink,
                  cardType !== 'default' ? [cardType + 'CardLink'] : ''
                )}
              >
                <ZbLink
                  to={link?.url}
                  type="base"
                  linkInNewTab={link?.linkInNewTab}
                  download={link?.download}
                >
                  {link?.text}
                </ZbLink>
              </div>
            ) : null}
          </div>
        ) : (
          <>
            {link?.url ? (
              <div
                className={clsx(
                  styles.cardLink,
                  cardType !== 'default' ? styles[cardType + 'CardLink'] : ''
                )}
              >
                <ZbLink
                  to={link?.url}
                  type="base"
                  linkInNewTab={link?.linkInNewTab}
                  download={link?.download}
                >
                  {link?.text}
                </ZbLink>
              </div>
            ) : null}
            {withButton && (
              <div
                className={clsx(
                  styles.cardCTA,
                  cardType !== 'default' ? styles[cardType + 'CTA'] : '',
                  {
                    [styles.pageTitleElementBtn]: onPageTitleElement,
                  }
                )}
              >
                <ZbLink
                  to={cta?.url}
                  type={cta?.type}
                  isExternal={isCtaExternal}
                  size={ctaBtnSize}
                  linkInNewTab={cta?.linkInNewTab}
                  download={cta?.download}
                  className={clsx(styles.cardCTA, {
                    [styles.pageTitleElementBtn]: onPageTitleElement,
                  })}
                >
                  <span>{cta?.text}</span>
                </ZbLink>
              </div>
            )}
          </>
        )}
      </Flex>
    </Flex>
  )
}
