'use client'

import ZbSlider from '@/components/ZbSlider'
import QuickstartCard from '@/components/VolumeCalculatorQuickstart/QuickstartCard'
import styles from './stepone.module.css'
import { useQuickstartContext } from '@/components/VolumeCalculatorQuickstart/quickstartContext'

export default function StepOne({ locations }: { locations: any }) {
  const { quickstartSelections } = useQuickstartContext()
  const noSelection = quickstartSelections.location === null
  return (
    <ZbSlider
      centerModeDesktop={false}
      centerModeMobile={true}
      centerPaddingMobile="40px"
      className={styles.slider}
      dotsMobile={false}
    >
      {locations.map((item, index) => (
        <QuickstartCard
          key={index}
          siteCode={item.id}
          title={{ text: item.name, size: 'h4' }}
          imgZoom={false}
          text={item.address}
          noSelection={noSelection}
          className={[
            'locationCard',
            locations.length === 1 ? 'singleCard' : '',
          ]}
          step={1}
          cardType="carousel"
          image={{
            url: `${item.mediaSrc}`,
            alt: item.name,
          }}
        />
      ))}
    </ZbSlider>
  )
}
