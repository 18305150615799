import styles from './svgicons.module.css'

export default function SVGPermalink({
  size = { width: '18px', height: '10px' },
  color = 'white',
  bgColor = 'black',
  hasHighlight = false,
}: {
  size?: { width: string; height: string }
  color?: string
  bgColor?: string
  hasHighlight?: boolean
}) {
  return (
    <svg
      fill={color}
      width={size.width}
      height={size.height}
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={hasHighlight ? styles.highlightOnHover : ''}
    >
      <path
        data-name="layer2"
        d="M32 14.4l8.8-8.8a12.4 12.4 0 0 1 17.6 0 12.4 12.4 0 0 1 0 17.6L44.3 37.3a12.4 12.4 0 0 1-17.6 0 12.6 12.6 0 0 1-1.6-1.9"
        fill="none"
        stroke="#202020"
        strokeMiterlimit="10"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
      <path
        data-name="layer1"
        d="M32 49.6l-8.8 8.8a12.4 12.4 0 0 1-17.6 0 12.4 12.4 0 0 1 0-17.6l14.1-14.1a12.4 12.4 0 0 1 17.6 0 12.5 12.5 0 0 1 1.6 1.9"
        fill="none"
        stroke="#202020"
        strokeMiterlimit="10"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
    </svg>
  )
}
