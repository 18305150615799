'use client'

import { useCallback, useEffect, useRef, useState } from 'react'
import PillButton from '@/components/buttons/PillButton'
import BlogArticlePreview from '@/components/BlogArticlePreview'
import styles from './BlogGrid.module.css'
import { slugify } from '@/utils/drupal.utils'
import { useAppSettings } from '@/context/AppSettingsContext'
import { Skeleton } from '@radix-ui/themes'
import { SetDrupalContextAlternates } from '@/context/DrupalContext'
import debounce from 'lodash.debounce'

type BlogGridProps = {
  locale: string
  category?: string
}

const PAGE_LIMIT = 10

type Category = {
  id: string
  name: string
}

export default function BlogGrid({ locale, category }: BlogGridProps) {
  const [state, setState] = useState<{
    categories: Category[]
    articles: any[]
    isLoading: boolean
    isLoadingMore: boolean
    page: number
    categoryId: string
    hasMore: boolean
    categoriesInitialized: boolean
  }>({
    categories: [],
    articles: [],
    isLoading: false,
    isLoadingMore: false,
    page: 0,
    categoryId: '',
    hasMore: true,
    categoriesInitialized: false,
  })

  const { appSettings } = useAppSettings()
  const appTranslations = appSettings?.translations

  const [termData, setTermData] = useState<any>(null)

  const categoriesContainerRef = useRef<HTMLDivElement>(null)

  const fetchCategories = useCallback(async () => {
    try {
      const response = await fetch(`/backend/tags?locale=${locale}`)
      if (!response.ok) throw new Error('Failed to fetch categories')
      return await response.json()
    } catch (error) {
      console.error('Error fetching categories:', error)
      return []
    }
  }, [locale])

  const fetchArticles = useCallback(
    async (categoryId: string, page: number) => {
      try {
        const params = new URLSearchParams({
          'page[limit]': PAGE_LIMIT.toString(),
          'page[offset]': (page * PAGE_LIMIT).toString(),
          'filter[langcode]': locale,
          ...(categoryId && { 'filter[field_category.id]': categoryId }),
        })

        const response = await fetch(`/backend/articles?${params}`)
        if (!response.ok) {
          throw new Error('Failed to fetch articles')
        }
        return await response.json()
      } catch (error) {
        console.error('Error fetching articles:', error)
        return []
      }
    },
    [locale]
  )

  useEffect(() => {
    const initialize = async () => {
      setState((prev) => ({ ...prev, isLoading: true }))
      const fetchedCategories = await fetchCategories()

      let initialCategoryId = ''
      if (category && category !== 'all-categories') {
        const matchingCategory = fetchedCategories.find(
          (cat) => slugify(cat.name) === category
        )
        if (matchingCategory) {
          initialCategoryId = matchingCategory.id
        }
      }

      setState((prev) => ({
        ...prev,
        categories: fetchedCategories,
        categoryId: initialCategoryId,
        categoriesInitialized: true,
        isLoading: false,
      }))
    }

    initialize()
  }, [fetchCategories, category])

  useEffect(() => {
    if (!state.categoriesInitialized) return

    const loadArticles = async () => {
      const { categoryId, page } = state
      setState((prev) => ({ ...prev, isLoadingMore: true }))

      const newArticles = await fetchArticles(categoryId, page)
      setState((prev) => ({
        ...prev,
        articles: page === 0 ? newArticles : [...prev.articles, ...newArticles],
        hasMore: newArticles.length === PAGE_LIMIT,
        isLoadingMore: false,
      }))
    }

    loadArticles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchArticles, state.categoriesInitialized, state.categoryId, state.page])

  useEffect(() => {
    if (state.categoryId) {
      fetch(
        `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/${locale}/jsonapi/taxonomy_term/tags/${state.categoryId}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            setTermData(data.data)
          }
        })
    }
  }, [state.categoryId, locale])

  const handleCategoryFilter = (category: any, isAllCategories: boolean) => {
    const newCategoryId = isAllCategories ? '' : category?.id || ''

    if (newCategoryId === state.categoryId) return

    setState((prev) => ({
      ...prev,
      page: 0,
      articles: [],
      hasMore: true,
      categoryId: newCategoryId,
    }))

    const newPath = isAllCategories
      ? `/${locale}/blog/${slugify(appTranslations?.[`${locale}_blog_allCategories`] || '')}`
      : `/${locale}/blog/${slugify(category?.name || '')}`

    window.history.pushState({}, '', newPath)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleScroll = useCallback(
    debounce(() => {
      const { isLoadingMore, hasMore } = state
      if (isLoadingMore || !hasMore) return

      const scrollPosition = window.innerHeight + window.scrollY
      const scrollThreshold = document.documentElement.offsetHeight - 600

      if (scrollPosition >= scrollThreshold) {
        setState((prev) => ({ ...prev, page: prev.page + 1 }))
      }
    }, 100),
    [state.isLoadingMore, state.hasMore]
  )

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll)
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll)
      debouncedHandleScroll.cancel()
    }
  }, [debouncedHandleScroll])

  const scrollToActiveCategory = useCallback(() => {
    if (window.innerWidth <= 599 && categoriesContainerRef.current) {
      requestAnimationFrame(() => {
        const container = categoriesContainerRef.current
        if (!container) return

        const activeButtons = container.querySelectorAll('button')
        const activeButton = Array.from(activeButtons).find((button) =>
          button.className.includes('activePillButton')
        ) as HTMLElement

        if (activeButton) {
          const containerWidth = container.offsetWidth
          const buttonLeft = activeButton.offsetLeft
          const buttonWidth = activeButton.offsetWidth

          container.scrollLeft =
            buttonLeft - containerWidth / 2 + buttonWidth / 2
        }
      })
    }
  }, [])

  useEffect(() => {
    scrollToActiveCategory()
  }, [state.categoryId, scrollToActiveCategory])

  const { categories, articles, isLoading, page, categoryId } = state

  if (isLoading && page === 0) {
    return (
      <div className={styles.blogGrid}>
        <div className={styles.categories}>
          {[...Array(5)].map((_, i) => (
            <Skeleton
              key={i}
              width="100px"
              height="32px"
              style={{ borderRadius: '16px' }}
            />
          ))}
        </div>
        <div className={styles.articlesGrid}>
          {[...Array(6)].map((_, i) => (
            <div key={i} className={styles.articleItem}>
              <Skeleton width="100%" height="300px" />
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <>
      {termData && (
        <SetDrupalContextAlternates
          alternates={termData.attributes.alternates}
        />
      )}
      <div className={styles.blogGrid}>
        {categories.length > 0 && (
          <div className={styles.categories} ref={categoriesContainerRef}>
            <PillButton
              id=""
              text={appTranslations?.[`${locale}_blog_allCategories`] || ''}
              active={!categoryId}
              onPillClick={() => handleCategoryFilter(null, true)}
            />
            {categories.map((category) => (
              <PillButton
                key={category.id}
                id={category.id}
                text={category.name}
                active={category.id === categoryId}
                onPillClick={() => handleCategoryFilter(category, false)}
              />
            ))}
          </div>
        )}

        <div className={styles.articlesGrid}>
          {articles.map((article) => (
            <div key={article.id} className={styles.articleItem}>
              <BlogArticlePreview article={article} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
