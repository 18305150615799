import { IconButton } from '@radix-ui/themes'
import clsx from 'clsx'
import styles from '@/components/Gallery/Gallery.module.css'
import SVGArrow from '@/components/SVGIcons/SVGArrow'
import React from 'react'

export const SliderArrow = ({
  className,
  direction,
  onClick,
  width = '20px',
  height = '20px',
}: {
  onClick?: () => void
  direction: 'left' | 'right'
  className?: string
  width?: string
  height?: string
}) => {
  return (
    <IconButton
      onClick={onClick}
      radius={'full'}
      className={clsx(
        className,
        styles.sliderArrow,
        direction === 'left' ? styles.sliderArrowLeft : styles.sliderArrowRight
      )}
    >
      <SVGArrow size={{ width, height }} color="black" direction={direction} />
    </IconButton>
  )
}
