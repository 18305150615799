'use client'

import BlogGrid from '@/components/BlogGrid'
import { ParagraphTeaserBlogGrid as ParagraphTeaserBlogGridType } from '@/lib/drupal-entities'
import { useParams } from 'next/navigation'

type ParagraphTeaserBlogGridProps = {
  paragraph: ParagraphTeaserBlogGridType
}

export default function ParagraphTeaserBlogGrid({
  paragraph,
}: ParagraphTeaserBlogGridProps) {
  const params = useParams()
  const locale = params ? (params.locale as string) : ''

  return <BlogGrid locale={locale} />
}
