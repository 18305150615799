'use client'

import React from 'react'
import styles from './progressbar.module.css'
import { useQuickstartContext } from '@/components/VolumeCalculatorQuickstart/quickstartContext'
import clsx from 'clsx'

const ProgressBar = () => {
  const { currentStep, setCurrentStep, quickstartSelections } =
    useQuickstartContext()

  const stepClicked = (step: number) => {
    if (isStepValid(step)) {
      setCurrentStep(step)
    }
  }

  const isStepValid = (step: number) => {
    switch (step) {
      case 0:
        return quickstartSelections.location !== null
      case 1:
        return quickstartSelections.customerType !== null
      case 2:
        return quickstartSelections.size !== null
      default:
        return false
    }
  }

  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.progressWithCircle}>
        <div
          className={styles.progressBar}
          style={{ width: `${(currentStep / 2) * 100}%` }} // Adjust the width calculation if needed
        />
      </div>

      <div className={styles.circles}>
        {[...Array(3)].map((_, i) => (
          <div
            key={i}
            className={clsx(
              styles.circle,
              { [styles.active]: currentStep >= i },
              { [styles.current]: currentStep === i },
              { [styles.clickable]: isStepValid(i) }
            )}
            onClick={() => stepClicked(i)}
          />
        ))}
      </div>
    </div>
  )
}

export default ProgressBar
