import React from 'react'
import styles from './RatingItem.module.css'
import { Box, Flex } from '@radix-ui/themes'
import DOMPurify from 'isomorphic-dompurify'
import clsx from 'clsx'
import ZbImage from '@/components/ZbImage'

type RatingItemProps = {
  goldText?: boolean
  htmlContent: string
  image: string
  name: string
}

const RatingItem = ({
  goldText = false,
  htmlContent,
  image,
  name,
}: RatingItemProps) => {
  return (
    <Flex
      direction="column"
      align="center"
      pb="24px"
      height="inherit"
      className={styles.zbRatingItemHeight}
      justify="between"
    >
      <Box
        className={clsx(
          styles.zbRatingItem_text,
          goldText ? styles.goldText : ''
        )}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlContent) }}
      />
      <Box>
        <ZbImage
          className={styles.zbRatingItem_image}
          src={image}
          alt={name}
          height={94}
          width={94}
        />
        <Box className={styles.zbRatingItem_name}>{name}</Box>
      </Box>
    </Flex>
  )
}

export default RatingItem
