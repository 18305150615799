'use client'

import { Flex } from '@radix-ui/themes'
import styles from './steptwo.module.css'
import { useQuickstartContext } from '@/components/VolumeCalculatorQuickstart/quickstartContext'
import clsx from 'clsx'

export default function StepTwo({
  typeOfCustomer,
  typeOfContent,
}: {
  typeOfCustomer: { id: number; name: string }[]
  typeOfContent: { id: number; name: string }[]
}) {
  const { setCustomerType, setContentType, quickstartSelections } =
    useQuickstartContext()

  const handleCustomerTypeClick = (customerTypeID: number) => {
    if (quickstartSelections.customerType === customerTypeID) {
      setCustomerType(null)
    } else {
      if (customerTypeID === 1) {
        setContentType(null)
      }

      setCustomerType(customerTypeID)
    }
  }

  const handleContentTypeClick = (contentTypeID: number) => {
    if (quickstartSelections.contentType === contentTypeID) {
      setContentType(null)
    } else {
      setContentType(contentTypeID)
    }
  }

  return (
    <Flex
      direction="column"
      gap="40px"
      width="100%"
      mb="20px"
      p={{ md: '0 50px 0 50px' }}
    >
      <Flex className={styles.directionHandler} gap="30px">
        {typeOfCustomer.map((customerType) => (
          <div
            key={customerType.id}
            className={clsx(styles.typeCard, {
              [styles.active]:
                customerType.id === quickstartSelections.customerType,
              [styles.cardShadow]:
                customerType.id === quickstartSelections.customerType ||
                quickstartSelections.customerType === null,
            })}
            onClick={() => handleCustomerTypeClick(customerType.id)}
          >
            <div
              className={clsx(
                styles.cardRadio,
                customerType.id === quickstartSelections.customerType
                  ? styles.active
                  : null
              )}
            >
              <div className={styles.cardRadioInner} />
            </div>
            <div className={styles.cardRadioText}>{customerType.name}</div>
          </div>
        ))}
      </Flex>
      {quickstartSelections.customerType === 0 && (
        <div className={styles.border} />
      )}
      <Flex className={styles.directionHandler} gap="30px">
        {quickstartSelections.customerType === 0 ? (
          <>
            {typeOfContent.map((contentType) => (
              <div
                key={contentType.id}
                className={clsx(styles.typeCard, {
                  [styles.active]:
                    contentType.id === quickstartSelections.contentType,
                  [styles.cardShadow]:
                    contentType.id === quickstartSelections.contentType ||
                    quickstartSelections.contentType === null,
                })}
                onClick={() => handleContentTypeClick(contentType.id)}
              >
                <div
                  className={clsx(
                    styles.cardRadio,
                    contentType.id === quickstartSelections.contentType
                      ? styles.active
                      : null
                  )}
                >
                  <div className={styles.cardRadioInner} />
                </div>
                <div className={styles.cardRadioText}>{contentType.name}</div>
              </div>
            ))}
          </>
        ) : (
          ''
        )}
      </Flex>
    </Flex>
  )
}
