export default function SVGPin({
  size = { width: '18px', height: '10px' },
  color = 'white',
  bgColor = 'black',
  hasHighlight = false,
}: {
  size?: { width: string; height: string }
  color?: string
  bgColor?: string
  hasHighlight?: boolean
}) {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 62 62"
      className={hasHighlight ? 'highlightOnHover' : ''}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        data-name="layer1"
        className={hasHighlight ? 'highlightOnHover' : ''}
        d="M32 2a20 20 0 0 0-20 20c0 18 20 40 20 40s20-22 20-40A20 20 0 0 0 32 2zm0 28a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"
        fill={bgColor}
      ></path>
    </svg>
  )
}
