import React, { useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import Inline from 'yet-another-react-lightbox/plugins/inline'
import { Box, IconButton } from '@radix-ui/themes'
import styles from '@/components/Gallery/Gallery.module.css'
import { SliderArrow } from '@/components/SliderArrow'
import clsx from 'clsx'
import { ControllerRef } from 'yet-another-react-lightbox/dist/types'
import SVGClose from '@/components/SVGIcons/SVGClose'
import loadingImage from 'public/zebra-texture.svg'
import NextJsImageSlide from '@/components/Gallery/NextJsImageSlide'
import { useIsMobile } from '@/hooks/useIsMobile'
import SVGRotateLeft from '@/components/SVGIcons/SVGRotateLeft'
import SVGRotateRight from '@/components/SVGIcons/SVGRotateRight'

export function ImagePreview({ images, open, setOpen, activeIndex }) {
  const ref = React.useRef<ControllerRef>(null)
  const isMobile = useIsMobile(599)
  const [rotation, setRotation] = useState(0)

  const slides = images.map((image) => ({
    src: isMobile ? image.urlMobile : image.url,
    alt: image.alt,
    width: image.width,
    height: image.height,
    blurDataURL: loadingImage,
  }))

  const rotateImage = (angle) => {
    setRotation((prevRotation) => prevRotation + angle)
  }

  const closePreview = () => {
    setOpen(false)
    setRotation(0)
  }

  const PrevArrow = ({ onClick }) => (
    <SliderArrow
      width={'24px'}
      height={'24px'}
      direction={'left'}
      onClick={onClick}
      className={styles.previewArrowLeft}
    />
  )

  const CloseArrow = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      radius={'full'}
      className={clsx(styles.closeButton, styles.sliderArrow)}
    >
      <SVGClose size={{ width: '14px', height: '14px' }} color="black" />
    </IconButton>
  )

  const NextArrow = ({ onClick }) => (
    <SliderArrow
      width={'24px'}
      height={'24px'}
      direction={'right'}
      onClick={onClick}
      className={styles.previewArrowRight}
    />
  )

  return (
    open && (
      <>
        <div className={styles.blurredBackground}>
          <PrevArrow onClick={() => ref.current?.next(activeIndex)} />
          <CloseArrow onClick={() => closePreview()} />
          <Box className={styles.previewBox}>
            <Lightbox
              styles={{
                root: {
                  '--yarl__container_background_color': 'transparent',
                  transform: `rotate(${rotation}deg)`,
                  transition: 'transform 0.5s ease-out',
                },
              }}
              animation={{
                swipe: 0,
              }}
              controller={{ ref }}
              open={open}
              index={activeIndex}
              close={() => closePreview()}
              slides={slides}
              plugins={[Inline]}
              render={{
                buttonPrev: () => null,
                buttonNext: () => null,
                slide: NextJsImageSlide,
              }}
            />
          </Box>

          <NextArrow onClick={() => ref.current?.next(activeIndex)} />
          <div className={styles.rotateButtons}>
            <IconButton
              className={styles.rotateButton}
              onClick={() => rotateImage(-90)}
            >
              <SVGRotateLeft
                bgColor={'#e10a0a'}
                color={'#fff'}
                size={{ width: '20px', height: '20px' }}
              />
            </IconButton>
            <IconButton
              onClick={() => rotateImage(90)}
              className={styles.rotateButton}
            >
              <SVGRotateRight
                bgColor={'#542727'}
                color={'#fff'}
                size={{ width: '20px', height: '20px' }}
              />
            </IconButton>
          </div>
        </div>
      </>
    )
  )
}
