'use client'

import clsx from 'clsx'
import Image from 'next/image'
import React, { useState } from 'react'
import { Box } from '@radix-ui/themes'
import Slider, { Settings } from 'react-slick'
import styles from '@/components/Gallery/Gallery.module.css'
import { ImagePreview } from '@/components/Gallery/ImagePreview'
import { SliderArrow } from '@/components/SliderArrow'
import 'yet-another-react-lightbox/styles.css'

type Image = {
  alt: string
  id: string
  type: string
  urlMobile: string
  url: string
  width: number
  height: number
}

type GalleryProps = {
  imagesList: Image[]
  className: string
}

export function Gallery({ imagesList, className }: GalleryProps) {
  const [open, setOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(-1)

  const settings: Settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    focusOnSelect: true,
    nextArrow: <SliderArrow width={'24px'} height={'24px'} direction="right" />,
    prevArrow: <SliderArrow width={'24px'} height={'24px'} direction="left" />,
    responsive: [
      {
        breakpoint: 959,
        settings: {
          dots: true,
          dotsClass: 'slick-dots',
          centerMode: true,
          centerPadding: '50px',
        },
      },
    ],
  }

  const previewImage = (index: number) => {
    setActiveIndex(index)
    setOpen(true)
  }

  return (
    <Box className={clsx('appGallery', className)}>
      <ImagePreview
        activeIndex={activeIndex}
        images={imagesList}
        open={open}
        setOpen={setOpen}
      />

      <Slider {...settings}>
        {imagesList?.map((media, index) => (
          <Box key={media.id} className={styles.sliderSlide}>
            <Image
              alt={media.alt}
              blurDataURL={'/zebra-texture.svg'}
              className={styles.responsiveImage}
              fill
              onClick={() => previewImage(index)}
              placeholder={'blur'}
              src={media.url}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  )
}
