'use client'

import * as Accordion from '@radix-ui/react-accordion'
import clsx from 'clsx'
import styles from './faq.module.css'
import ZbLink from '@/components/ZbLink'
import SVGPlus from '../SVGIcons/SVGPlus'
import SVGMinus from '../SVGIcons/SVGMinus'
import { FAQProps } from './faq.types'
import DOMPurify from 'isomorphic-dompurify'
import { wrapTables } from '@/hooks/useSanitizedHtml'
import { HtmlWithOptionalScrollbar } from '@/components/HtmlWithOptionalScrollbar/HtmlWithOptionalScrollbar'
import { JSONSchemaFAQ } from '@/components/FAQ/JSONSchemaFAQ'
import { Fragment } from 'react'

export default function ZbFAQ({
  title,
  subtitle,
  entriesList,
  hasBackground,
  btnText,
  btnLink,
}: FAQProps) {
  return (
    <Fragment>
      <JSONSchemaFAQ props={entriesList} />
      <div className={clsx(styles.faq, hasBackground && styles['faq--withBg'])}>
        {(title || subtitle) && (
          <div className={styles.faq__header}>
            {title && <h2 className={styles.faq__title}>{title}</h2>}
            {subtitle && (
              <div
                className={styles.faq__subtitle}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(wrapTables(subtitle) || ''),
                }}
              />
            )}
          </div>
        )}

        <div className={styles.faq__accordion}>
          <Accordion.Root
            type="single"
            collapsible
            className={styles.faq__root}
          >
            {entriesList.map((entry) => (
              <Accordion.Item
                key={entry.id}
                value={entry.id}
                className={styles.faq__item}
              >
                <Accordion.Trigger className={styles.faq__trigger}>
                  <span className={styles.faq__text}>{entry.field_text}</span>
                  <div className={styles.faq__icons}>
                    <SVGPlus
                      className={styles.faq__iconPlus}
                      size={{ width: '24px', height: '24px' }}
                    />
                    <SVGMinus
                      className={styles.faq__iconMinus}
                      size={{ width: '24px', height: '24px' }}
                    />
                  </div>
                </Accordion.Trigger>
                <Accordion.Content className={styles.faq__content}>
                  <HtmlWithOptionalScrollbar
                    html={entry.field_paragraphs_text?.processed}
                  />
                </Accordion.Content>
              </Accordion.Item>
            ))}
          </Accordion.Root>
        </div>

        {btnText && btnLink && (
          <div className={styles.faq__buttonWrapper}>
            <ZbLink
              to={btnLink}
              type="button"
              size="large"
              className={styles['faq__button']}
            >
              <span>{btnText}</span>
            </ZbLink>
          </div>
        )}
      </div>
    </Fragment>
  )
}
