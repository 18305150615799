'use client'

import { useEffect, useRef } from 'react'
import { Map } from '@/components/Map'
import DOMPurify from 'isomorphic-dompurify'
import styles from '../Map/map.module.css'
import ZbSlider from '@/components/ZbSlider'
import Card from '@/components/Card'

type ParagraphHomeMapProps = any

export default function HomeMap({
  field_title,
  field_lead,
  processedLocations,
}: ParagraphHomeMapProps) {
  const leadRef = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    if (leadRef.current) {
      leadRef.current.innerHTML = DOMPurify.sanitize(field_lead.processed)
    }
  }, [field_lead.processed])

  return (
    <div className={styles.homeMap}>
      <div className={styles.homeMapHeader}>
        <h2>{field_title}</h2>
        <div className={styles.homeMapLead}>
          <p ref={leadRef} />
        </div>
      </div>
      <div className={styles.homeMapContainer}>
        <Map data={processedLocations} isHomeMap={true} />
      </div>
      <div className={styles.homeMapSlider}>
        <ZbSlider
          dotsDesktop={false}
          dotsMobile={true}
          centerModeMobile={true}
          centerPaddingMobile="30px"
          className="mapSlider"
        >
          {processedLocations.map((location, index) => (
            <Card
              key={index}
              title={{ text: location?.field_title, size: 'h4' }}
              imgZoom={true}
              text={location?.field_lead}
              cardType="carousel"
              className={processedLocations.length === 1 ? 'singleCard' : ''}
              image={{
                url: `${location?.field_media}`,
                alt: location?.field_title,
              }}
              cta={{
                text: location?.field_cta?.title,
                type: 'button',
                url: location?.field_cta?.url,
              }}
            />
          ))}
        </ZbSlider>
      </div>
    </div>
  )
}
