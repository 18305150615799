export default function SVGPlus({
  size = { width: '18px', height: '10px' },
  color = 'black',
  className,
}: {
  size?: { width: string; height: string }
  color?: string
  className?: string
}) {
  return (
    <svg
      className={className}
      width={size.width}
      height={size.height}
      viewBox="0 0 25 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill={color}
    >
      <g stroke="none" strokeWidth="1">
        <g id="UI-plus" transform="translate(-12.000000, -12.000000)">
          <g
            id="Combined-Shape"
            transform="translate(24.131728, 24.131728) rotate(45.000000) translate(-24.131728, -24.131728) translate(15.646447, 15.646447)"
          >
            <path d="M16.263456,-1.29318778e-12 L16.9705627,0.707106781 L9.192,8.485 L16.9705627,16.263456 L16.263456,16.9705627 L8.485,9.192 L0.707106781,16.9705627 L-3.8624659e-13,16.263456 L7.778,8.485 L-3.83693077e-13,0.707106781 L0.707106781,-1.29318778e-12 L8.485,7.778 L16.263456,-1.29318778e-12 Z" />
          </g>
        </g>
      </g>
    </svg>
  )
}
