'use client'

import { useState } from 'react'
import {
  APILoadingStatus,
  APIProvider,
  Map as GoogleMaps,
  useApiLoadingStatus,
} from '@vis.gl/react-google-maps'
import styles from './map.module.css'
import { Skeleton } from '@radix-ui/themes'
import clsx from 'clsx'
import { useLocale } from 'next-intl'
import { useEffect } from 'react'
import PinMarker from '@/components/Map/Marker/PinMarker'
import CustomZoomControl from '@/components/Map/ZoomControl/CustomZoomControl'

const API_KEY = process.env.NEXT_PUBLIC_GOOGLEMAPS_API_KEY

export function Map({
  data,
  isHomeMap = false,
}: {
  data: any[]
  isHomeMap?: boolean
}) {
  const locale = useLocale()
  const status = useApiLoadingStatus()
  const [selectedLocation, setSelectedLocation] = useState<any | null>(null)
  const [zoom, setZoom] = useState(13)
  const [center, setCenter] = useState({ lat: 46.82, lng: 8.2 })

  useEffect(() => {
    if (status === APILoadingStatus.FAILED) {
      console.error('Failed to load Google Maps API')

      return
    }
  }, [status])

  useEffect(() => {
    if (data.length === 1) {
      setCenter({
        lat: parseFloat(data[0].field_latitude || data[0].latitude),
        lng: parseFloat(data[0].field_longitude || data[0].longitude),
      })

      setZoom(14)
    } else if (data.length > 1) {
      const avgLat =
        data.reduce(
          (acc, location) =>
            acc + parseFloat(location.field_latitude || location.latitude),
          0
        ) / data.length
      const avgLng =
        data.reduce(
          (acc, location) =>
            acc + parseFloat(location.field_longitude || location.longitude),
          0
        ) / data.length

      setCenter({ lat: avgLat, lng: avgLng })
    }

    if (data.length > 3 && !isHomeMap) {
      setZoom(10)
      if (data.length > 6) {
        setZoom(8)
      }
    } else {
      setZoom(9)
    }
  }, [data, isHomeMap])

  const [hoverId, setHoverId] = useState<string | null>(null)
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const [selectedMarker, setSelectedMarker] =
    useState<google.maps.marker.AdvancedMarkerElement | null>(null)

  const onMouseEnter = (id: string | null) => setHoverId(id)
  const onMouseLeave = () => setHoverId(null)
  const onMarkerClick = (marker) => {
    setSelectedMarker(marker)
  }

  useEffect(() => {
    const matchedLocation = data.find((location) => location.field_highlighted)

    if (matchedLocation) {
      setSelectedLocation(
        matchedLocation.sitecode || matchedLocation.field_sitecode
      )
      setSelectedId(matchedLocation.sitecode || matchedLocation.field_sitecode)
    }
  }, [data])

  const onMapClick = () => {
    setSelectedId(null)
    setSelectedMarker(null)
  }

  const onCloseInfoWindow = () => {
    setSelectedId(null)
    setSelectedMarker(null)
  }

  return status === APILoadingStatus.LOADING ? (
    <Skeleton className={clsx(styles.mapContainer, styles.mapStyles)} />
  ) : (
    <APIProvider
      apiKey={API_KEY}
      language={locale}
      region={process.env.NEXT_PUBLIC_COUNTRY_CODE}
    >
      <GoogleMaps
        mapId={process.env.NEXT_PUBLIC_GOOGLEMAPS_MAP_ID}
        disableDefaultUI
        renderingType="VECTOR"
        className={clsx(styles.mapContainer, styles.mapStyles)}
        defaultCenter={center}
        zoom={zoom}
        onClick={onMapClick}
        clickableIcons={false}
        onZoomChanged={(ev) => setZoom(ev.detail.zoom)}
      >
        <CustomZoomControl zoom={zoom} onZoomChange={(zoom) => setZoom(zoom)} />
        {data.map((location, index) => (
          <PinMarker
            key={index}
            isHomeMap={isHomeMap}
            selectedLocation={selectedLocation}
            selectedId={selectedId}
            selectedMarker={selectedMarker}
            hoverId={hoverId}
            locationData={location}
            onClose={onMapClick}
            onCloseInfoWindow={onCloseInfoWindow}
            onMarkerClick={onMarkerClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        ))}
      </GoogleMaps>
    </APIProvider>
  )
}
