'use client'

import React, { useEffect, useRef } from 'react'
import { Box, Flex, Grid } from '@radix-ui/themes'
import styles from './ZbRatings.module.css'
import DOMPurify from 'isomorphic-dompurify'
import Slider from 'react-slick'
import RatingItem from '@/components/ZbRatingItem'
import ZbImage from '@/components/ZbImage'
import clsx from 'clsx'
import { wrapTables } from '@/hooks/useSanitizedHtml'

type ZbRatingsComponentProps = {
  ctaLink: {
    url: string
    title?: string
  }
  imageSource: string
  linkInNewTab?: boolean
  ratingsList: Array<{
    id: string
    image?: string
    title?: string
    text?: string
  }>
  textRight: string
  titleLeft: string
  titleRight: string
  className?: string
}

const ZbRatingsComponent = ({
  ctaLink,
  imageSource,
  linkInNewTab = false,
  ratingsList,
  textRight,
  titleLeft,
  titleRight,
  className,
}: ZbRatingsComponentProps) => {
  const getRatingId = (rating: { id: string }) => rating?.id ?? ''
  const sliderRef = useRef<Slider>(null)

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: 'slick-dots',
  }

  useEffect(() => {
    const resizeSliderItems = () => {
      const sliderItems = document.querySelectorAll(`.${styles.zbRatingItem}`)
      let maxHeight = 0

      sliderItems.forEach((item) => {
        const itemHeight = item.clientHeight
        if (itemHeight > maxHeight) {
          maxHeight = itemHeight
        }
      })

      sliderItems.forEach((item) => {
        ;(item as HTMLElement).style.height = `${maxHeight}px`
      })
    }

    resizeSliderItems()
    window.addEventListener('resize', resizeSliderItems)

    return () => {
      window.removeEventListener('resize', resizeSliderItems)
    }
  }, [ratingsList])

  return (
    <Grid columns="12" className={clsx('zbRatings', className)}>
      <Box gridColumn={{ initial: 'span 12', md: '2/span 10' }}>
        <Grid columns="2" gap={{ initial: '4', md: '0' }}>
          <Flex
            gap="2"
            direction="column"
            gridColumn={{ initial: 'span 2', sm: 'span 1' }}
            className={styles.zbRatingsLeftColumn}
          >
            <div className={styles.zbRatingsColumnTitle}>{titleLeft}</div>
            <Slider ref={sliderRef} {...settings}>
              {ratingsList.map((rating) => (
                <div key={getRatingId(rating)} className={styles.zbRatingItem}>
                  <RatingItem
                    image={rating.image || ''}
                    name={rating.title || ''}
                    htmlContent={rating.text || ''}
                    goldText={true}
                  />
                </div>
              ))}
            </Slider>
          </Flex>
          <Flex
            gap="3"
            direction="column"
            gridColumn={{ initial: 'span 2', sm: 'span 1' }}
            className={styles.zbRatingsRightColumn}
          >
            <Box className={styles.zbRatingsColumnTitle}>{titleRight}</Box>
            <div
              className={styles.zbRatingsRightColumnText}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(wrapTables(textRight) || ''),
              }}
            />
            <ZbImage
              width={210}
              height={100}
              className={styles.zbRatingsRightColumnImage}
              src={imageSource}
              alt="Rating Image"
              loading="eager"
            />
            {ctaLink && (
              <a
                href={ctaLink.url}
                target={linkInNewTab ? '_blank' : '_self'}
                className={styles.zbRatingsRightColumnCtaLink}
              >
                {ctaLink.title}
              </a>
            )}
          </Flex>
        </Grid>
      </Box>
    </Grid>
  )
}

export default ZbRatingsComponent
