import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import clsx from 'clsx'

interface CustomScrollbarProps {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const CustomScrollbar: React.FC<CustomScrollbarProps> = ({
  children,
  className = '',
  style,
}) => {
  return (
    <PerfectScrollbar
      className={clsx('custom-scrollbar', className)}
      options={{
        suppressScrollY: true,
        handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
      }}
      style={style}
    >
      {children}
    </PerfectScrollbar>
  )
}

export default CustomScrollbar
