'use client'

import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import FAQ from '@/components/FAQ'
import { ParagraphFaqs as ParagraphFaqsType } from '@/lib/drupal-entities'
import styles from '@/components/FAQ/faqs.module.css'
import SVGChevron from '@/components/SVGIcons/SVGChevron'
import { useAppSettings } from '@/context/AppSettingsContext'
import { useLocale } from 'next-intl'

type ParagraphTeaserFaqsProps = {
  paragraph: {
    field_content: Array<ParagraphFaqsType>
  }
}

export default function ParagraphFaqs({ paragraph }: ParagraphTeaserFaqsProps) {
  const locale = useLocale()
  const { appSettings } = useAppSettings()
  const appTranslations = appSettings?.translations

  const [activeCluster, setActiveCluster] = useState<string>('0')
  const [filters, setFilters] = useState<Array<{ id: string; title: string }>>([
    {
      id: '0',
      title: appTranslations?.[`${locale}_faq_allTopics`] || 'All Topics',
    },
  ])
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(true)
  const filterScrollRef = useRef<HTMLDivElement>(null)
  const scrollTimeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const storedId = sessionStorage.getItem('faqCatId')
    if (storedId) {
      setActiveCluster(storedId)
    }
  }, [])

  useEffect(() => {
    if (paragraph.field_content?.length) {
      const newFilters = paragraph.field_content.map((cluster) => ({
        id: cluster.id,
        title: cluster.field_title || '',
      }))
      setFilters((prevFilters) => [prevFilters[0], ...newFilters])
    }
  }, [paragraph.field_content])

  const checkScroll = useCallback(() => {
    if (filterScrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = filterScrollRef.current
      const maxScroll = scrollWidth - clientWidth

      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current)
      }

      scrollTimeout.current = setTimeout(() => {
        if (filterScrollRef.current) {
          const { scrollLeft, scrollWidth, clientWidth } =
            filterScrollRef.current
          const maxScroll = scrollWidth - clientWidth

          setCanScrollLeft(scrollLeft > 0)
          setCanScrollRight(Math.ceil(scrollLeft) < maxScroll - 1)
        }
      }, 150)
    }
  }, [])

  const scroll = useCallback((direction: 'left' | 'right') => {
    if (filterScrollRef.current) {
      const container = filterScrollRef.current
      const containerWidth = container.clientWidth

      let targetScroll =
        direction === 'right'
          ? container.scrollLeft + containerWidth
          : container.scrollLeft - containerWidth

      const maxScroll = container.scrollWidth - containerWidth
      targetScroll = Math.max(0, Math.min(targetScroll, maxScroll))

      setCanScrollLeft(targetScroll > 0)
      setCanScrollRight(targetScroll < maxScroll)

      container.scrollTo({
        left: targetScroll,
        behavior: 'smooth',
      })
    }
  }, [])

  const handleFilterClick = useCallback((id: string) => {
    setActiveCluster(id)
    sessionStorage.setItem('faqCatId', id)

    if (filterScrollRef.current) {
      const container = filterScrollRef.current
      const selectedButton = container.querySelector(`button[data-id="${id}"]`)

      if (selectedButton) {
        const buttonRect = selectedButton.getBoundingClientRect()
        const containerRect = container.getBoundingClientRect()

        if (
          buttonRect.left < containerRect.left ||
          buttonRect.right > containerRect.right
        ) {
          selectedButton.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest',
          })
        }
      }
    }
  }, [])

  useEffect(() => {
    checkScroll()
    window.addEventListener('resize', checkScroll)

    const scrollElement = filterScrollRef.current
    if (scrollElement) {
      scrollElement.addEventListener('scroll', checkScroll)
    }

    return () => {
      window.removeEventListener('resize', checkScroll)
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', checkScroll)
      }
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current)
      }
    }
  }, [checkScroll])

  const filteredClusters = useMemo(
    () =>
      paragraph.field_content?.filter(
        (cluster) => activeCluster === '0' || cluster.id === activeCluster
      ),
    [paragraph.field_content, activeCluster]
  )

  const processEntries = (entries: any[]) => {
    return entries
      .map((item) => {
        const isLibrary = item.type === 'paragraph--from_library'
        const source = isLibrary
          ? item?.field_reusable_paragraph?.paragraphs
          : item

        if (
          !source ||
          (isLibrary && !item?.field_reusable_paragraph?.paragraphs)
        ) {
          return null
        }

        return {
          field_text: source.field_text ?? '',
          id: source.id,
          field_paragraphs_text: source.field_paragraphs_text ?? '',
        }
      })
      .filter(Boolean)
  }

  return (
    <div className={styles.faqsContainer}>
      {filters.length > 2 && (
        <div className={styles.filterContainer}>
          {canScrollLeft && (
            <button
              className={styles.scrollButton}
              onClick={() => scroll('left')}
              aria-label="Scroll left"
            >
              <SVGChevron
                direction="left"
                size={{ width: '14px', height: '14px' }}
                color="var(--secondary-1)"
              />
            </button>
          )}

          <div className={styles.filterScroll} ref={filterScrollRef}>
            {filters.map((filter) => (
              <button
                key={filter.id}
                data-id={filter.id}
                className={`${styles.filterButton} ${
                  activeCluster === filter.id ? styles.activeFilter : ''
                }`}
                onClick={() => handleFilterClick(filter.id)}
              >
                {filter.title}
              </button>
            ))}
          </div>

          {canScrollRight && (
            <button
              className={styles.scrollButton}
              onClick={() => scroll('right')}
              aria-label="Scroll right"
            >
              <SVGChevron
                direction="right"
                size={{ width: '14px', height: '14px' }}
                color="var(--secondary-1)"
              />
            </button>
          )}
        </div>
      )}

      {filteredClusters?.map((cluster) => (
        <FAQ
          key={cluster.id}
          subtitle={cluster.field_title || ''}
          entriesList={processEntries(cluster.field_content || []).filter(
            (entry): entry is NonNullable<typeof entry> => entry !== null
          )}
          hasBackground={false}
        />
      ))}
    </div>
  )
}
