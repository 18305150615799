'use client'

import { Button } from '@radix-ui/themes'
import styles from './circleArrowbutton.module.css'
import SVGArrow from '@/components/SVGIcons/SVGArrow'

export default function CircleArrowButton({
  className = '',
  disabled = false,
  direction = 'right',
  onClick,
}: {
  className?: string
  disabled?: boolean
  direction?: 'up' | 'down' | 'left' | 'right'
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}) {
  return (
    <Button
      className={` ${styles.arrowButton} ${disabled ? styles.disabledCtaButton : ''} ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      <SVGArrow
        size={{
          width: '26px',
          height: '24px',
        }}
        direction={direction}
      />
    </Button>
  )
}
