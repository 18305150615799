'use client'

import Ratings from '../ZbRatings'
import { imageDomain } from '@/lib/config'
import { ParagraphRatings as ParagraphRatingsType } from '../../lib/drupal-entities'

type ParagraphRatingsProps = {
  paragraph: ParagraphRatingsType
}

export default function ParagraphRatings({ paragraph }: ParagraphRatingsProps) {
  // Extract values from paragraph.
  const ctaLink = paragraph?.field_cta ?? { url: '', title: '' }
  const imageSource = `${imageDomain}${paragraph?.field_media?.field_media_image?.uri?.url}`
  const linkInNewTab = paragraph?.field_link_in_new_tab ?? false
  const ratingsList =
    paragraph?.field_content?.map((rating) => ({
      id: rating.id,
      image: rating?.field_media?.field_media_image?.uri?.url
        ? `${imageDomain}${rating.field_media.field_media_image.uri.url}`
        : '/default-image-path.jpg',
      title: rating.field_title,
      text: rating.field_lead.processed,
    })) ?? []
  const textRight = paragraph?.field_lead?.processed ?? ''
  const titleLeft = paragraph?.field_title_left ?? ''
  const titleRight = paragraph?.field_title_right ?? ''

  return (
    <Ratings
      ctaLink={ctaLink}
      imageSource={imageSource}
      ratingsList={ratingsList}
      textRight={textRight}
      titleLeft={titleLeft}
      titleRight={titleRight}
      linkInNewTab={linkInNewTab}
      className="zb-druxt-entity-paragraph-margin-bottom"
    />
  )
}
