'use client'

import { useEffect } from 'react'
import { useSearchParams } from 'next/navigation'

const UtmTracker = () => {
  const searchParams = useSearchParams()

  useEffect(() => {
    const utmParams: Record<string, string> = {}
    for (const [key, value] of searchParams.entries()) {
      if (key.startsWith('utm_')) {
        utmParams[key] = value
      }
    }

    if (Object.keys(utmParams).length > 0) {
      fetch('/api/qs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(utmParams),
      })
    }
  }, [searchParams])

  return null
}

export default UtmTracker
