'use client'

import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import ReCAPTCHA from 'react-google-recaptcha'
import { TextField } from '@mui/material'
import styles from './DirectionsAndContactForm.module.css'
import { useLocale } from 'next-intl'
import ZbLink from '@/components/ZbLink'
import { useAppSettings } from '@/context/AppSettingsContext'
import DOMPurify from 'isomorphic-dompurify'
import { wrapTables } from '@/hooks/useSanitizedHtml'

interface Link {
  uri: string
  title: string
}

interface DirectionsAndContactFormProps {
  firstColumnHtml: string
  secondColumnHtml: string
  thirdColumnLinks: Link[]
  sitecode?: string
  confirmationpage?: string
  submitTitle?: string
  submitText?: string
}

type FormData = {
  email: string
  subject: string
  message: string
}

export default function DirectionsAndContactForm({
  firstColumnHtml,
  secondColumnHtml,
  thirdColumnLinks,
  sitecode,
  confirmationpage,
  submitTitle,
  submitText,
}: DirectionsAndContactFormProps) {
  const locale = useLocale()
  const [isRecaptchaValid, setIsRecaptchaValid] = useState(false)
  const [showSubmitText, setShowSubmitText] = useState(false)
  const { appSettings } = useAppSettings()
  const appTranslations = appSettings?.translations

  const formSchema = z.object({
    email: z
      .string()
      .email(appTranslations?.[`${locale}_smallform_email_error_empty`]),
    subject: z.string().optional(),
    message: z.string().min(1, 'Message is required'),
  })
  const onRecaptchaChange = (token: string | null) => {
    setIsRecaptchaValid(!!token)
  }
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      subject: '',
      message: '',
    },
  })

  const handleFormSubmit = async (data: FormData) => {
    try {
      const response = await fetch('/backend/contact_location', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          sitecode,
        }),
      })

      if (!response.ok) {
        throw new Error('Submission failed')
      }

      reset()
      setShowSubmitText(true)
    } catch (error) {
      console.error('Form submission error:', error)
    }
  }

  return (
    <div className={styles.directionsAndContactForm}>
      <div className={styles.row}>
        <div className={styles.column}>
          <div
            className={styles.textColumn}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(wrapTables(firstColumnHtml) || ''),
            }}
          />
        </div>
        <div className={styles.column}>
          <div
            className={styles.textColumn}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(wrapTables(secondColumnHtml) || ''),
            }}
          />
        </div>
        <div className={styles.column}>
          {thirdColumnLinks.length > 0 && (
            <div className={styles.thirdColumnLinks}>
              <div className={styles.contactUsTitle}>
                {appTranslations?.[`${locale}_smallform_title`]}
              </div>
              <div className={styles.linksContainer}>
                {thirdColumnLinks.map((link, index) => (
                  <ZbLink
                    key={index}
                    to={link.uri}
                    type="button"
                    size="medium"
                    linkInNewTab
                  >
                    <span>{link.title}</span>
                  </ZbLink>
                ))}
              </div>
            </div>
          )}

          {showSubmitText ? (
            <div className={styles.submitConfirmation}>
              <div className={styles.submitTitle}>{submitTitle}</div>
              <p>{submitText}</p>
            </div>
          ) : (
            <form
              onSubmit={handleSubmit(handleFormSubmit)}
              className={styles.form}
            >
              <div className={styles.formRow}>
                <div className={styles.formCol}>
                  <h5 className={styles.sectionTitle}>
                    {appTranslations?.[`${locale}_contact_cta_write_title`]}
                  </h5>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={
                          appTranslations?.[`${locale}_smallform_email_label`]
                        }
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        fullWidth
                        variant="outlined"
                        required
                        className={styles.input}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formCol}>
                  <Controller
                    name="subject"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={
                          appTranslations?.[`${locale}_smallform_subject_label`]
                        }
                        fullWidth
                        variant="outlined"
                        className={styles.input}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formCol}>
                  <Controller
                    name="message"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={
                          appTranslations?.[`${locale}_smallform_message_label`]
                        }
                        error={!!errors.message}
                        helperText={errors.message?.message}
                        fullWidth
                        variant="outlined"
                        required
                        className={styles.textarea}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={styles.actions}>
                <div className={styles.recaptchaCol}>
                  <ReCAPTCHA
                    sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
                    onChange={onRecaptchaChange}
                    hl={locale}
                  />
                </div>
                <div className={styles.submitCol}>
                  <button
                    type="submit"
                    disabled={!isValid || !isRecaptchaValid}
                    className={styles.submitButton}
                  >
                    {appTranslations?.[`${locale}_smallform_send_label`]}
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}
