'use client'

import { useState, useEffect, useMemo, useCallback } from 'react'
import styles from './GridPackagingMaterial.module.css'
import { useResources } from '@/context/ResourcesContext'
import { ParagraphGridPackagingMaterialEntry } from '@/lib/drupal-entities'
import { slugify } from '@/utils/drupal.utils'
import Card from '@/components/Card'
import ZbTabs from '@/components/Tabs'
import { Box, Grid } from '@radix-ui/themes'

interface GridPackagingMaterialProps {
  content: ParagraphGridPackagingMaterialEntry[]
}

const computeCategories = (content: ParagraphGridPackagingMaterialEntry[]) => {
  return content.reduce(
    (acc, item) => {
      item.field_categories.forEach((category) => {
        if (!acc.find((c) => c.id === category.id)) {
          acc.push(category)
        }
      })
      return acc
    },
    [] as { id: string; name: string }[]
  )
}

const formatPrice = (price: number): string => {
  const priceString = price.toString()
  return priceString.endsWith('.00')
    ? priceString.slice(0, -3) + '–'
    : priceString
}

export default function GridPackagingMaterial({
  content,
}: GridPackagingMaterialProps) {
  const resources = useResources()
  const categories = useMemo(() => computeCategories(content), [content])
  const [currentFilter, setCurrentFilter] = useState<string | null>(null)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
    const hash = window.location.hash.substring(1)
    if (hash) {
      const category = categories.find((cat) => slugify(cat.name) === hash)
      if (category) {
        setCurrentFilter(category.id)
      }
    } else {
      setCurrentFilter('all')
    }
  }, [categories])

  const tabData = useMemo(() => {
    const data = [
      { field_title: resources.general_all, field_link: 'all' },
      ...categories.map((category) => ({
        field_title: category.name,
        field_link: slugify(category.name),
      })),
    ]
    return data
  }, [categories, resources.general_all])

  const handleTabChange = useCallback(
    (tabValue: string) => {
      const selectedTab = tabData.find((tab) => tab.field_link === tabValue)
      if (selectedTab) {
        const category = categories.find(
          (cat) => slugify(cat.name) === selectedTab.field_link
        )
        setCurrentFilter(category ? category.id : 'all')
      } else {
        setCurrentFilter('all')
      }
    },
    [tabData, categories]
  )

  const filteredContent = useMemo(() => {
    const filtered = content.filter((item) => {
      if (currentFilter === 'all') {
        return true
      }
      return item.field_categories.some(
        (category) => category.id === currentFilter
      )
    })
    return filtered
  }, [content, currentFilter])

  const renderGrid = useMemo(
    () => (
      <Grid columns={{ initial: '1', sm: '2', md: '3' }}>
        {filteredContent.map((item, index) => (
          <Box m="6" key={`${item.field_title}-${index}`}>
            <Card
              className={styles.parkagingMaterialCard}
              title={{ text: item.field_title, size: 'h4' }}
              text={item.field_text}
              image={{
                url: item.field_media,
                size: { w: 260, h: 200 },
                subtitle: item.field_title,
                alt: item.field_title,
              }}
              withButton={false}
              withShadow={false}
            >
              <div className={styles.itemContainer}>
                {item.field_eyecatcher_text && (
                  <div className={styles.badge}>
                    <div className={styles.badgeText}>
                      {item.field_eyecatcher_text}
                    </div>
                  </div>
                )}
                <div className={styles.size}>{item.field_size}</div>
                <div className={styles.price}>
                  CHF {formatPrice(item.field_price)}
                </div>
              </div>
            </Card>
          </Box>
        ))}
      </Grid>
    ),
    [filteredContent]
  )

  const tabContents = useMemo(
    () => tabData.map(() => renderGrid),
    [tabData, renderGrid]
  )

  if (!isClient || currentFilter === null) {
    return null
  }

  return (
    <div className={styles.gridPackagingMaterialContainer}>
      <ZbTabs
        data={tabData}
        tabContent={tabContents}
        className={styles.packagingTabsList}
        onValueChange={handleTabChange}
        disableScroll={true}
      />
    </div>
  )
}
