import clsx from 'clsx'
import styles from './badge.module.css'

type BadgeProps = {
  title: string
  subtitle: string
  rotateRight?: boolean
}

export default function ZbBadge({
  title,
  subtitle,
  rotateRight = true,
}: BadgeProps) {
  return (
    <div
      className={clsx(styles.badge, {
        [styles['badge--rotate-right']]: rotateRight,
        [styles['badge--rotate-left']]: !rotateRight,
      })}
    >
      <span className={styles.badge__content}>
        <h4 className={styles.badge__heading}>{title}</h4>
        <p className={styles.badge__subtitle}>{subtitle}</p>
      </span>
    </div>
  )
}
