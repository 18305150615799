export default function SVGCheckmark({
  size = { width: '18px', height: '10px' },
  color = 'black',
  className,
}: {
  size?: { width: string; height: string }
  color?: string
  className?: string
}) {
  return (
    <svg
      className={className}
      width={size.width}
      height={size.height}
      viewBox="0 0 29 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill={color}
    >
      <g stroke="none" strokeWidth="1">
        <g id="picto-checkmark" transform="translate(-6.000000, -7.000000)">
          <path d="M6,21.1963 L8.45346021,18.261012 L15.8138408,23.4039533 C15.8138408,23.4039533 20.2039792,17.5432772 24.8750865,13.3061667 C29.2301038,9.35120031 33.8861592,7 33.8861592,7 L35,8.47011893 C35,8.47011893 30.8155709,11.875647 26.9070934,17.0235382 C22.0051903,23.4782018 17.1534602,31.9722222 17.1534602,31.9722222 L6,21.1963 Z" />
        </g>
      </g>
    </svg>
  )
}
