'use client'

import clsx from 'clsx'
import styles from './vcquickstart.module.css'
import { useResources } from '@/context/ResourcesContext'
import StepOne from '@/components/VolumeCalculatorQuickstart/StepOne'
import StepTwo from '@/components/VolumeCalculatorQuickstart/StepTwo'
import StepThree from '@/components/VolumeCalculatorQuickstart/StepThree'
import CTAButton from '@/components/buttons/CTAButton'
import ProgressBar from '@/components/VolumeCalculatorQuickstart/ProgressBar'
import { useQuickstartContext } from '@/components/VolumeCalculatorQuickstart/quickstartContext'
import ZbLink from '@/components/ZbLink'
import { useSanitizedHtml } from '@/hooks/useSanitizedHtml'

export default function VolumeCalculatorQuickstart({ data }: { data: any }) {
  const { currentStep, setCurrentStep, quickstartSelections } =
    useQuickstartContext()
  const resources = useResources()

  const sanitizedFooterText = useSanitizedHtml(data?.footer)
  const sanitizedHintText = useSanitizedHtml(data?.hint)

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return <StepOne locations={data?.locations} />
      case 1:
        return (
          <StepTwo
            typeOfCustomer={data?.privateOrBusiness}
            typeOfContent={data?.archiveOrWares}
          />
        )
      case 2:
        return <StepThree sizes={data?.ve_sizes} />
      default:
        return <StepOne locations={data?.locations} />
    }
  }

  const enableNextStep = () => {
    switch (currentStep) {
      case 0:
        return quickstartSelections.location !== null
      case 1:
        if (quickstartSelections.customerType === 0) {
          return quickstartSelections.contentType !== null
        } else {
          return quickstartSelections.customerType !== null
        }
      case 2:
        return quickstartSelections.size !== null
      default:
        return false
    }
  }

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1)
  }

  return (
    <div
      className={clsx(
        styles.quickstartContainer,
        'zb-druxt-entity-paragraph-margin-bottom'
      )}
    >
      <div className={styles.quickstartContent}>
        <div className={styles.quickstartContentHeader}>
          <div className={styles.quickstartContentTitle}>{data?.title}</div>
          <ProgressBar />
          <div className={styles.quickstartContentSubtitle}>
            {data?.steps[`step${currentStep + 1}`]}
          </div>
        </div>
        <div className={styles.stepContainer}>{renderCurrentStep()}</div>
        {!enableNextStep() ? (
          <div
            className={styles.quickstartHint}
            dangerouslySetInnerHTML={{
              __html: sanitizedHintText,
            }}
          />
        ) : (
          <div className={styles.quickstartNextButton}>
            {currentStep === 2 ? (
              <ZbLink
                to={`${resources.volumecalc_url}#/?location=${quickstartSelections.location}&firm=${quickstartSelections.customerType === 0 ? 1 : 0}&setTypeOfGoods=${quickstartSelections.contentType}&size=${quickstartSelections.size}`}
                type="button"
              >
                {resources?.general_next}
              </ZbLink>
            ) : (
              <CTAButton
                text={resources?.general_next}
                onClick={handleNextStep}
              />
            )}
          </div>
        )}
      </div>
      <div
        className={clsx(styles.quickstartFooter, styles.text)}
        dangerouslySetInnerHTML={{
          __html: sanitizedFooterText,
        }}
      />
      <div className={styles.quickstartBackground} />
    </div>
  )
}
