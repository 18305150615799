'use client'

import React, { useState } from 'react'
import clsx from 'clsx'
import styles from './YouTubeVideo.module.css'
import { useLocale } from 'next-intl'
import Spinner from '@/components/Spinner'

type YouTubeVideoProps = {
  youtubeUrl: string
}

const YouTubeVideo = ({ youtubeUrl }: YouTubeVideoProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const locale = useLocale()

  const youtubeId = (() => {
    const regExp =
      /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    const match = youtubeUrl.match(regExp) ?? []
    return match.length >= 2 && match[1] && match[1].length === 11
      ? match[1]
      : false
  })()

  return (
    <div className={clsx(styles.youtube__video, 'youtube')}>
      {isLoading && (
        <div className={clsx(styles.spinner__container)}>
          <Spinner className={styles.spinner} />
        </div>
      )}

      {youtubeId && (
        <iframe
          src={
            'https://www.youtube-nocookie.com/embed/' +
            /* A unique YouTube ID */
            youtubeId +
            /* Do not display player controls in the video player. */
            '?controls=0' +
            /* Sets the player's interface language. */
            `&hl=${locale}` +
            /**
             * Prevent the YouTube logo from displaying in the control bar.
             * A YouTube text label or watermark still displays when the user's
             * mouse pointer hovers over the player.
             */
            '&modestbranding=1' +
            /**
             * Specifies the color used in the player's video progress bar
             * to show the amount of the video that the viewer has already seen.
             */
            '&color=white' +
            /**
             * Try to play automatically - will not work on all devices
             */
            '&autoplay=1' +
            '&mute=1' +
            /**
             * according to zebrabox, the videos should always loop.
             */
            '&loop=1' +
            /**
             * needed for looping to work
             */
            `&playlist=${youtubeId}`
          }
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className={clsx(styles.youtube__video__iframe, {
            [styles.hidden]: isLoading,
          })}
          onLoad={() => setIsLoading(false)}
        />
      )}
    </div>
  )
}

export default YouTubeVideo
