'use client'

import { useEffect, useState } from 'react'
import styles from './ArticleShare.module.css'
import SVGFacebook from '@/components/SVGIcons/SVGFacebook'
import SVGEnvelope from '@/components/SVGIcons/SVGEnvelope'
import SVGPermalink from '@/components/SVGIcons/SVGPermalink'
import { useAppSettings } from '@/context/AppSettingsContext'

interface ArticleShareProps {
  content: any
  locale: string
}

export default function ArticleShare({ content, locale }: ArticleShareProps) {
  const { appSettings } = useAppSettings()
  const appTranslations = appSettings?.translations
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [facebookSharingUrl, setFacebookSharingUrl] = useState<string | null>(
    null
  )

  useEffect(() => {
    const getFacebookSharingUrl = () => {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.origin + '/' + locale + content.path.alias)}`
    }

    setFacebookSharingUrl(getFacebookSharingUrl())
  }, [])

  const mailSharingUrl = `mailto:contact@zebrabox.com?subject=${encodeURIComponent(content.title)}&body=${encodeURIComponent(content.field_preview_lead.processed)}`

  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(
      window.location.origin + '/' + locale + content.path.alias
    )
    setSnackbarVisible(true)
    setTimeout(() => setSnackbarVisible(false), 3000)
  }

  return (
    <div className={styles.shareContainer}>
      <a
        className={styles.shareLink}
        href={facebookSharingUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <SVGFacebook
          size={{ width: '48px', height: '48px' }}
          bgColor="white"
          color="#000000"
        />
      </a>
      <a
        className={styles.shareLink}
        href={mailSharingUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <SVGEnvelope size={{ width: '32px', height: '32px' }} />
      </a>
      <div className={styles.shareLink} onClick={copyUrlToClipboard}>
        <SVGPermalink size={{ width: '28px', height: '28px' }} />
      </div>
      {snackbarVisible && (
        <div className={styles.snackbar}>
          {appTranslations?.[`${locale}_blog_copiedToClipboard`]}
        </div>
      )}
    </div>
  )
}
