import Image from 'next/image'
import {
  isImageFitCover,
  isImageSlide,
  useLightboxProps,
  useLightboxState,
} from 'yet-another-react-lightbox'
import { useEffect, useState } from 'react'

function isNextJsImage(slide) {
  return (
    isImageSlide(slide) &&
    typeof slide.width === 'number' &&
    typeof slide.height === 'number'
  )
}

export default function NextJsImageSlide({ slide, offset, rect }) {
  const {
    on: { click },
    carousel: { imageFit },
  } = useLightboxProps()

  const { currentIndex } = useLightboxState()

  const cover = isImageSlide(slide) && isImageFitCover(slide, imageFit)

  const [isBlurred, setIsBlurred] = useState(true)

  useEffect(() => {
    setIsBlurred(true)
    const timer = setTimeout(() => setIsBlurred(false), 500)
    return () => clearTimeout(timer)
  }, [currentIndex])

  if (!isNextJsImage(slide)) {
    return null
  }

  const width = !cover
    ? Math.round(
        Math.min(rect.width, (rect.height / slide.height) * slide.width)
      )
    : rect.width

  const height = !cover
    ? Math.round(
        Math.max(rect.height, (rect.width / slide.width) * slide.height + 40)
      )
    : rect.height

  return (
    <Image
      fill
      alt={slide.alt}
      src={slide}
      loading="eager"
      draggable={false}
      blurDataURL="/zebra-texture.svg"
      placeholder="blur"
      style={{
        objectFit: cover ? 'cover' : 'contain',
        cursor: click ? 'pointer' : undefined,
        opacity: isBlurred ? 0 : 1,
        transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
      }}
      sizes={`${Math.ceil((width / window.innerWidth) * 100)}vw`}
      onClick={
        offset === 0 ? () => click?.({ index: currentIndex }) : undefined
      }
    />
  )
}
