'use client'

import { useEffect, useState } from 'react'
import ZbContentImage from '@/components/ZbContentImage'
import { ParagraphImageVideoContentWidth as ParagraphImageVideoContentWidthType } from '../../lib/drupal-entities'
import { absoluteUrl } from '@/lib/drupal'
import ZbContentWidthYoutube from '@/components/ZbContentWidthYoutube'

type ParagraphImageVideoContentWidthProps = {
  paragraph: ParagraphImageVideoContentWidthType
}

export default function ParagraphImageVideoContentWidth({
  paragraph,
}: ParagraphImageVideoContentWidthProps) {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const badgeTitle = paragraph?.field_eyecatcher_title ?? ''
  const badgeSubtitle = paragraph?.field_eyecatcher_text ?? ''
  const overlayTitle = paragraph?.field_image_caption ?? ''
  const youtubeUrl = paragraph?.field_video ?? ''

  const imageSrc = absoluteUrl(
    paragraph?.field_media?.field_media_image?.uri?.url ?? ''
  )

  //@ts-ignore: The id is not in the field_media_image object
  const imageUuid = paragraph?.field_media?.field_media_image?.id ?? ''

  if (!isMounted) {
    return null
  }

  if (youtubeUrl) {
    return (
      <ZbContentWidthYoutube
        youtubeUrl={youtubeUrl}
        className="zb-druxt-entity-paragraph-margin-bottom"
      />
    )
  }

  if (imageSrc) {
    return (
      <ZbContentImage
        src={imageSrc}
        uuid={imageUuid}
        badgeTitle={badgeTitle}
        badgeSubtitle={badgeSubtitle}
        overlayTitle={overlayTitle}
        className="zb-druxt-entity-paragraph-margin-bottom"
      />
    )
  }

  return null
}
