'use client'

import { useEffect, useRef, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { useAppSettings } from '@/context/AppSettingsContext'
import { useLocale } from 'next-intl'
import styles from './LocationInput.module.css'
import clsx from 'clsx'
import DOMPurify from 'isomorphic-dompurify'
import SVGSearch from '@/components/SVGIcons/SVGSearch'
import SVGClose from '@/components/SVGIcons/SVGClose'
import { wrapTables } from '@/hooks/useSanitizedHtml'

interface LocationInputProps {
  className?: string
}

interface LocationOption {
  label: string
  value: string
  url?: string
  isHeader?: boolean
}

export default function LocationInput({ className }: LocationInputProps) {
  const locale = useLocale()

  const { appSettings } = useAppSettings()

  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<LocationOption[]>([])

  const inputRef = useRef<HTMLInputElement | null>(null)
  const popperRef = useRef<HTMLDivElement | null>(null)
  const autocompleteRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !inputRef.current?.contains(event.target as Node) &&
        !popperRef.current?.contains(event.target as Node) &&
        !autocompleteRef.current?.contains(event.target as Node)
      ) {
        setOpen(false)
        handleClear()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleInputChange = async (_: any, newInputValue: string) => {
    setInputValue(newInputValue)
    setOpen(true)

    if (newInputValue.length >= 1) {
      setLoading(true)
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/${locale}/locationsearch?search=${encodeURIComponent(newInputValue)}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        const data = await response.json()

        const transformedData: LocationOption[] = []

        data.forEach((location: any) => {
          if (location.header) {
            transformedData.push({
              label: location.header,
              value: location.header,
              isHeader: true,
            })
          } else {
            transformedData.push({
              label: location.text.replace(
                /<span class="d-none">.*?<\/span>/,
                ''
              ),
              value: location.value,
              url:
                appSettings?.translations?.[`${locale}_volumecalc_url`] +
                `#/?location=${location.value.split('|').pop()}`,
            })
          }
        })

        setOptions(transformedData)
      } catch (error) {
        console.error('Error fetching locations:', error)
        setOptions([])
      } finally {
        setLoading(false)
      }
    } else {
      setOptions([])
    }
  }

  const handleChange = (_: any, newValue: LocationOption | null) => {
    if (newValue?.url && !newValue.isHeader) {
      setOpen(false)
    }
  }

  const handleClear = () => {
    setInputValue('')
    setOptions([])
    setOpen(false)
  }

  return (
    <div className={clsx(styles.LocationInputContainer, className)}>
      <Autocomplete
        ref={autocompleteRef}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={(_event, reason) => {
          setOpen(reason === 'blur')
        }}
        loading={loading}
        slotProps={{
          popper: {
            className: 'LocationInputPopper',
            ref: popperRef,
          },
        }}
        disableCloseOnSelect={true}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleChange}
        options={options}
        disableClearable
        getOptionLabel={(option: LocationOption | null) => {
          if (!option || option.isHeader) return ''
          return option.label?.replace(/<[^>]*>/g, '') || ''
        }}
        isOptionEqualToValue={(option, value) => {
          if (!option || !value || option.isHeader) return false
          return option.value === value.value
        }}
        renderOption={({ onClick, key, ...otherProps }, option) =>
          option?.isHeader ? (
            <li
              className={clsx(styles.groupHeader)}
              key={option?.value}
              {...otherProps}
            >
              <p>{option?.label}</p>
            </li>
          ) : (
            <li key={option?.value} onClick={onClick} {...otherProps}>
              <a
                href={option.url}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(wrapTables(option?.label || '')),
                }}
              />
            </li>
          )
        }
        filterOptions={(x) => x}
        noOptionsText={
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                wrapTables(
                  appSettings?.translations?.[
                    `${locale}_home_searchForYourNearestLocation`
                  ] || ''
                )
              ),
            }}
          />
        }
        forcePopupIcon={false}
        onBlur={(e) => {
          if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
            return
          }
          // setTimeout(() => {
          //   if (document.activeElement?.tagName !== 'LI') {
          //     setOpen(false)
          //   }
          // }, 200)
        }}
        onFocus={() => setOpen(true)}
        blurOnSelect={false}
        clearOnBlur={false}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant="outlined"
            inputRef={inputRef}
            onBlur={(e) => {
              e.preventDefault()
            }}
          />
        )}
        className={styles.autocomplete}
      />
      {inputValue ? (
        <button onClick={handleClear} className={styles.closeButton}>
          <SVGClose
            size={{
              width: '14',
              height: '14',
            }}
            color="#939393"
            className={styles.closeIcon}
          />
        </button>
      ) : (
        <SVGSearch
          size={{
            width: '24',
            height: '24',
          }}
          className={styles.searchIcon}
        />
      )}
    </div>
  )
}
