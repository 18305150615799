import styles from './spinner.module.css'
import clsx from 'clsx'

type SpinnerProps = {
  className?: string
}

export default function Spinner({ className }: SpinnerProps) {
  return (
    <div className={clsx(styles.spinner, className)}>
      <svg
        className={styles.spinnerSvg}
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={styles.spinnerCircle}
          fill="transparent"
          cx="25"
          cy="25"
          r="20"
          strokeWidth="5"
        />
      </svg>
    </div>
  )
}
