import clsx from 'clsx'
import { MouseEvent } from 'react'
import { Button, Flex } from '@radix-ui/themes'
import styles from './ctabutton.module.css'

type CTAButtonProps = {
  disabled?: boolean
  text: string
  size?: 'small' | 'medium' | 'large'
  className?: string
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
}

export default function CTAButton({
  disabled = false,
  text,
  size = 'medium',
  className,
  onClick,
}: CTAButtonProps) {
  return (
    <Flex>
      <Button
        className={clsx(
          styles.baseBtn,
          styles.ctaButton,
          styles[size],
          disabled && styles.disabledCtaButton,
          className
        )}
        disabled={disabled}
        title={text}
        onClick={onClick}
      >
        <span className={styles.ctaSpan}>{text}</span>
      </Button>
    </Flex>
  )
}
